/*import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonial</p>
        <h1 className="primary-heading">What They Are Saying</h1>
        <p className="primary-text">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <img src={ProfilePic} alt="" />
        <p>
          Sudoto feels exciting for me every time I use it. I've been learning
          15 new Japanese words every day, and when I go to watch anime or
          YouTube, my brain is able to just parse the language automatically. I
          don't think any other language app has that effect.
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>John Doe</h2>
      </div>
    </div>
  );
};

export default Testimonial;*/

import React from "react";
import PickMeals from "../Assets/john-doe-image.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";
import { AiFillStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const { t } = useTranslation();

  const workInfoData = [
    {
      image: PickMeals,
      title: t("testimonial.reviews.0.title"),
      text: t("testimonial.reviews.0.text"),
    },
    {
      image: ChooseMeals,
      title: t("testimonial.reviews.1.title"),
      text: t("testimonial.reviews.1.text"),
    },
    {
      image: DeliveryMeals,
      title: t("testimonial.reviews.2.title"),
      text: t("testimonial.reviews.2.text"),
    },
  ];

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">{t("testimonial.sectionTitle")}</p>
        <h1 className="primary-heading-2">{t("testimonial.sectionHeading")}</h1>
        <p className="primary-text">{t("testimonial.sectionText")}</p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
            <div className="testimonials-stars-container">
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
