import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="bg-white shadow-md rounded-lg max-w-3xl mx-auto p-8">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">
          {t("privacyPolicy.title")}
        </h1>
        <p className="text-lg text-gray-700 mb-4 text-center">
          {t("privacyPolicy.effectiveDate")}
        </p>

        {Object.keys(t("privacyPolicy.sections", { returnObjects: true })).map(
          (key, index) => {
            const section = t(`privacyPolicy.sections.${key}`, {
              returnObjects: true,
            });
            return (
              <React.Fragment key={index}>
                <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                  {section.title}
                </h2>
                <p className="text-base leading-relaxed mb-6 text-gray-600">
                  {section.text}
                </p>
                {section.list && (
                  <ul className="list-disc list-inside mb-6 text-gray-600">
                    {section.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {section.footer && (
                  <p className="text-base leading-relaxed mb-6 text-gray-600">
                    {Array.isArray(section.footer)
                      ? section.footer.map((footItem, fIdx) => (
                          <span key={fIdx}>
                            {footItem}
                            <br />
                          </span>
                        ))
                      : section.footer}
                  </p>
                )}
              </React.Fragment>
            );
          }
        )}

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          {t("privacyPolicy.sections.contactInformation.title")}
        </h2>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("privacyPolicy.sections.contactInformation.text")}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
