/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from "../Assets/Logo.svg";
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import LoginIcon from "@mui/icons-material/Login";
import { useTranslation } from "react-i18next";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail as resetPass,
} from "firebase/auth";
import { isEmail } from "validator";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";

const firebaseConfig = {
  apiKey: "AIzaSyCdXUtqrrtVwTFN-FcnbnqCHCzkfDfK-Zg",
  authDomain: "sudoto-app.firebaseapp.com",
  projectId: "sudoto-app",
  storageBucket: "sudoto-app.appspot.com",
  messagingSenderId: "270438936342",
  appId: "1:270438936342:web:416d57b1aed27b14032a37",
  measurementId: "G-VN2DDSLXQW",
  databaseURL: "https://sudoto-app-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const handleSignup = (e, t) => {
  e.preventDefault();
  Swal.fire({
    html: `
        <section class="signupsectionpopup">
          <form id="signupForm">
            <h1 class="h1signup">${t("auth.signUp")}</h1>
            <h4 class="signuph4">${t("auth.trial")}</h4>
            <h4 class="signuph4">${t("auth.noCard")}</h4>
            <div class="inputbox">
              <ion-icon name="mail-outline"></ion-icon>
              <input type="email" required id="email" />
              <label for="">${t("auth.email")}</label>
            </div>
            <div class="inputbox">
              <ion-icon name="lock-closed-outline"></ion-icon>
              <input type="password" required id="password" />
              <label for="">${t("auth.password")}</label>
            </div>
            <div class="forget">
              <a class="show-password">
                ${t("auth.showPassword")}
              </a>
            </div>
            <h4 class="signuph4">
        ${t("auth.termsOfService1")} <a href="${
      window.location.origin
    }/tos" style="text-decoration: underline;" target="_blank">${t(
      "auth.termsOfService"
    )}</a>${t("auth.and")}<a href="${
      window.location.origin
    }/privacy" style="text-decoration: underline;" target="_blank">${t(
      "auth.privacyPolicy1"
    )}</a>${t("auth.privacyPolicy")}
      </h4>
            <br>
            <button class="signupbutton" type="submit">${t(
              "auth.continue"
            )}</button>
          </form>
        </section>
      `,
    showConfirmButton: false,
    customClass: {
      popup: "signupbodypopup",
      content: "signupsectionpopup",
    },
    didOpen: () => {
      const showPasswordButton = document.querySelector(".forget a");
      const passwordInput = document.getElementById("password");

      showPasswordButton.addEventListener("click", (event) => {
        event.preventDefault();

        if (passwordInput.type === "password") {
          passwordInput.type = "text";
          showPasswordButton.textContent = t("auth.hidePassword");
        } else {
          passwordInput.type = "password";
          showPasswordButton.textContent = t("auth.showPassword");
        }
      });
      document
        .getElementById("signupForm")
        .addEventListener("submit", async (event) => {
          event.preventDefault();
          const email = document.getElementById("email").value;
          const password = document.getElementById("password").value;

          if (!isEmail(email)) {
            Swal.showValidationMessage(t("auth.validEmail"));
            return;
          }

          // Validate password
          if (password.length < 8) {
            Swal.showValidationMessage(t("auth.shortPassword"));
            return;
          }

          try {
            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );
            await sendEmailVerification(userCredential.user);
            window.location.href = "/app";
          } catch (error) {
            Swal.showValidationMessage(
              `${t("auth.errorSignUp")} ${error.message}`
            );
          }
        });
    },
  });
};

const handleLogin = (e, t) => {
  e.preventDefault();
  Swal.fire({
    html: `
        <section class="signupsectionpopup">
          <form id="signupForm">
            <h1 class="h1signup">${t("auth.login")}</h1>
            <div class="inputbox">
              <ion-icon name="mail-outline"></ion-icon>
              <input type="email" required id="email" />
              <label for="">${t("auth.email")}</label>
            </div>
            <div class="inputbox">
              <ion-icon name="lock-closed-outline"></ion-icon>
              <input type="password" required id="password" />
              <label for="">${t("auth.password")}</label>
            </div>
            <div class="forget">
              <a class="show-password">${t("auth.showPassword")}</a>
              <a class="show-password" id="resetpass">${t(
                "auth.forgotPassword"
              )}</a>
            </div>
            <button class="signupbutton" type="submit">${t(
              "auth.continue"
            )}</button>
          </form>
        </section>
      `,
    showConfirmButton: false,
    customClass: {
      popup: "signupbodypopup",
      content: "signupsectionpopup",
    },
    didOpen: () => {
      const showPasswordButton = document.querySelector(".forget a");
      const passwordInput = document.getElementById("password");

      const resetEmailButton = document.getElementById("resetpass");
      const emailInput = document.getElementById("email");

      showPasswordButton.addEventListener("click", (event) => {
        event.preventDefault();

        if (passwordInput.type === "password") {
          passwordInput.type = "text";
          showPasswordButton.textContent = t("auth.hidePassword");
        } else {
          passwordInput.type = "password";
          showPasswordButton.textContent = t("auth.showPassword");
        }
      });
      resetEmailButton.addEventListener("click", (event) => {
        event.preventDefault();

        const email = emailInput.value;

        if (!isEmail(email)) {
          Swal.showValidationMessage(t("auth.validEmail"));
          return;
        }

        resetPass(auth, email);
        Swal.showValidationMessage(t("auth.resetEmailSent"));
      });
      document
        .getElementById("signupForm")
        .addEventListener("submit", async (event) => {
          event.preventDefault();
          const email = document.getElementById("email").value;
          const password = document.getElementById("password").value;

          if (!isEmail(email)) {
            Swal.showValidationMessage(t("auth.validEmail"));
            return;
          }

          // Validate password
          if (password.length < 8) {
            Swal.showValidationMessage(t("auth.shortPassword"));
            return;
          }

          try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email, password);
            window.location.href = "/app";
          } catch (error) {
            Swal.showValidationMessage(
              `${t("auth.errorLogIn")} ${error.message}`
            );
          }
        });
    },
  });
};

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const { t } = useTranslation();

  const menuOptions = [
    {
      text: t("navbar.signUp"),
      icon: <SensorOccupiedIcon />,
      onClick: (e) => handleSignup(e, t),
    },
    {
      text: t("navbar.logIn"),
      icon: <LoginIcon />,
      onClick: (e) => handleLogin(e, t),
    },
  ];

  return (
    <nav>
      <div className="nav-logo-container"></div>
      <div className="navbar-links-container">
        <button className="primary-button" onClick={(e) => handleSignup(e, t)}>
          {t("navbar.signUp")}
        </button>
        <button className="primary-button" onClick={(e) => handleLogin(e, t)}>
          {t("navbar.logIn")}
        </button>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export { handleSignup, handleLogin };
export default Navbar;
