import React from "react";
import PickMeals from "../Assets/pick-meals-image.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";
import { useTranslation } from "react-i18next";

const Work = () => {
  const { t } = useTranslation();

  const workInfoData = [
    {
      image: PickMeals,
      title: t("work.infoData.0.title"),
      text: t("work.infoData.0.text"),
    },
    {
      image: ChooseMeals,
      title: t("work.infoData.1.title"),
      text: t("work.infoData.1.text"),
    },
    {
      image: DeliveryMeals,
      title: t("work.infoData.2.title"),
      text: t("work.infoData.2.text"),
    },
  ];

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">{t("work.sectionTitle")}</p>
        <h1 className="primary-heading-2">{t("work.sectionHeading")}</h1>
        <p
          className="primary-text"
          dangerouslySetInnerHTML={{ __html: t("work.sectionText") }}
        ></p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
