import React, { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { WebVTT } from "vtt.js";
import Swal from "sweetalert2";
// import dictionaryData from "./dictionaries/jmdict_english/JMdict.json";
import { toHiragana, toKana, toKatakana, toRomaji, tokenize } from "wanakana";

const apiUrl =
  window.location.hostname === "localhost" ? "http://localhost:5000" : "/api";

const Watch = () => {
  const [videoSrc, setVideoSrc] = useState(null);
  const [subtitles, setSubtitles] = useState([]);
  const [adjustedSubtitles, setAdjustedSubtitles] = useState([]); // New state for adjusted subtitles
  const [subtitleOffset, setSubtitleOffset] = useState(0);
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [playMode, setPlayMode] = useState("normal"); // 'normal' or 'pause'
  const [playbackSpeed, setPlaybackSpeed] = useState(1); // default speed
  const [replayType, setReplayType] = useState("normal"); // 'normal' or 'toNearestSubtitle'
  const [activeTab, setActiveTab] = useState("settings"); // 'settings' or 'track'
  const [selectedLanguage, setSelectedLanguage] = useState("japanese"); // default language
  const [inputValue, setInputValue] = useState(""); // state for the input value
  const videoRef = useRef(null);
  const subtitleOverlayRef = useRef(null);
  const [videoHeight, setVideoHeight] = useState(0);
  const [lastSubtitle, setLastSubtitle] = useState(null);
  const [currentTime, setCurrentTime] = useState(0); // Track the current time of the video
  const [highlightedSubtitle, setHighlightedSubtitle] = useState(null); // Track the currently highlighted subtitle
  const subtitleRefs = useRef([]);
  const [areSubsVisible, setAreSubsVisible] = useState(true); // Initially, subtitles are visible

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (
      file &&
      (file.type === "video/mp4" ||
        file.type === "video/quicktime" ||
        file.type === "video/MP2T" ||
        file.type === "video/x-matroska" ||
        file.type === "application/vnd.apple.mpegurl" ||
        file.name.endsWith(".ts"))
    ) {
      setVideoSrc(URL.createObjectURL(file));
    } else {
      alert("Unsupported file type. Please select a .mp4, .mov, or .mkv file.");
    }
  };

  const onDropSubtitle = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (file.name.endsWith(".vtt")) {
          parseVTT(reader.result);
        } else if (file.name.endsWith(".srt")) {
          parseSRT(reader.result);
        } else if (file.name.endsWith(".ass")) {
          parseASS(reader.result);
        } else {
          alert(
            "Unsupported subtitle file type. Please select a .srt, .vtt, or .ass subtitle file."
          );
        }
      };
      reader.readAsText(file);
    }
  };

  const parseASS = (assContent) => {
    // Normalize newlines to ensure consistency
    assContent = assContent.replace(/\r\n|\r|\n/g, "\n");

    // Find the Events section
    const eventsSection = assContent
      .split("[Events]")[1]
      .split("\n")
      .filter((line) => line.startsWith("Dialogue"));

    const assCues = eventsSection.map((cueLine) => {
      const parts = cueLine.split(",");
      const timeStart = parts[1].trim();
      const timeEnd = parts[2].trim();
      const text = parts.slice(9).join(",").replace(/\\N/g, "<br>"); // Join text split by commas and replace newline markers
      return {
        startTime: parseTime(timeStart),
        endTime: parseTime(timeEnd),
        text: text,
      };
    });

    setSubtitles(assCues);
  };

  const parseVTT = (vttContent) => {
    const parser = new WebVTT.Parser(window, WebVTT.StringDecoder());
    const cues = [];

    parser.oncue = (cue) => {
      cues.push(cue);
    };

    parser.onflush = () => {
      setSubtitles(cues);
    };

    parser.parse(vttContent);
    parser.flush();
  };

  const parseSRT = (srtContent) => {
    // Normalize newlines to ensure consistency
    srtContent = srtContent.replace(/\r\n|\r|\n/g, "\n");

    const srtCues = srtContent
      .split("\n\n")
      .filter(Boolean)
      .map((cueString) => {
        const lines = cueString
          .split("\n")
          .filter((line) => line.trim() !== ""); // Filter out empty lines

        if (lines.length < 3) return null; // Ensure there are at least 3 lines (index, time, text)

        const timeString = lines[1];
        const [start, end] = timeString.split(" --> ");
        const text = lines.slice(2).join("<br>");
        return {
          startTime: parseTime(start),
          endTime: parseTime(end),
          text: text,
        };
      })
      .filter(Boolean); // Remove null entries

    setSubtitles(srtCues);
  };

  const parseTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":");
    const [secs, ms] = seconds.split(",");
    return (
      parseInt(hours, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseFloat(secs + "." + ms)
    );
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const adjustSubtitles = (subtitles, offset) => {
    return subtitles.map((subtitle) => ({
      ...subtitle,
      startTime: subtitle.startTime + offset / 1000,
      endTime: subtitle.endTime + offset / 1000,
    }));
  };

  const handleOffsetChange = (event) => {
    const value =
      event.target.value === "" ? 0 : parseInt(event.target.value, 10);
    setSubtitleOffset(value);
  };

  const clearVideo = () => {
    setVideoSrc(null);
    setSubtitles([]);
    setSubtitleOffset(0);
    setCurrentSubtitle("");
    setLastSubtitle(null);
  };

  const clearSubtitles = () => {
    setSubtitles([]);
    setCurrentSubtitle("");
    setLastSubtitle(null);
  };

  const handlePlayModeChange = (event) => {
    setPlayMode(event.target.value);
  };

  const handlePlaybackSpeedChange = (event) => {
    setPlaybackSpeed(parseFloat(event.target.value));
  };

  const handleReplayTypeChange = (event) => {
    setReplayType(event.target.value);
  };

  const handleVideoSeek = (direction) => {
    if (videoRef.current && subtitles.length > 0) {
      const currentTime = videoRef.current.currentTime;
      const currentSubtitleIndex = subtitles.findIndex(
        (cue) => currentTime >= cue.startTime && currentTime <= cue.endTime
      );

      if (replayType === "toNearestSubtitle") {
        if (direction === "forward") {
          if (
            currentSubtitleIndex !== -1 &&
            currentSubtitleIndex < subtitles.length - 1
          ) {
            videoRef.current.currentTime =
              subtitles[currentSubtitleIndex + 1].startTime;
          } else {
            const nextCue = subtitles.find(
              (cue) => cue.startTime > currentTime
            );
            if (nextCue) {
              videoRef.current.currentTime = nextCue.startTime;
            }
          }
        } else if (direction === "backward") {
          if (currentSubtitleIndex > 0) {
            videoRef.current.currentTime =
              subtitles[currentSubtitleIndex - 1].startTime;
          } else {
            const prevCue = [...subtitles]
              .reverse()
              .find((cue) => cue.startTime < currentTime);
            if (prevCue) {
              videoRef.current.currentTime = prevCue.startTime;
            }
          }
        }
      } else {
        const seekTime = direction === "forward" ? 10 : -10; // Normal seek of 10 seconds
        videoRef.current.currentTime += seekTime;
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      "video/mp4, video/quicktime, video/MP2T, video/x-matroska, application/vnd.apple.mpegurl",
  });

  const {
    getRootProps: getSubtitleRootProps,
    getInputProps: getSubtitleInputProps,
  } = useDropzone({
    onDrop: onDropSubtitle,
    accept: ".srt, .vtt",
  });

  useEffect(() => {
    if (videoRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0]) {
          setVideoHeight(entries[0].contentRect.height);
        }
      });
      resizeObserver.observe(videoRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [videoSrc]);

  useEffect(() => {
    if (subtitles.length > 0) {
      setAdjustedSubtitles(adjustSubtitles(subtitles, subtitleOffset));
    }
  }, [subtitles, subtitleOffset]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && adjustedSubtitles.length > 0) {
      const displaySubtitle = () => {
        const currentTime = videoElement.currentTime * 1000; // Convert to milliseconds
        setCurrentTime(currentTime); // Update current time
        const activeCues = adjustedSubtitles.filter(
          (cue) =>
            currentTime >= cue.startTime * 1000 &&
            currentTime <= cue.endTime * 1000
        );
        if (activeCues.length > 0) {
          setCurrentSubtitle(activeCues[0].text.replace(/\n/g, "<br>"));
          const currentSubtitleIndex = adjustedSubtitles.findIndex(
            (cue) => cue.startTime === activeCues[0].startTime
          );
          setHighlightedSubtitle(currentSubtitleIndex);
          if (playMode === "pause" && activeCues[0] !== lastSubtitle) {
            videoElement.pause();
            setLastSubtitle(activeCues[0]);
          }
          videoElement.playbackRate = 1; // Reset to normal speed when subtitles are showing
        } else {
          setCurrentSubtitle("");
          videoElement.playbackRate = playbackSpeed; // Set to selected speed when no subtitles are showing
        }
      };

      videoElement.addEventListener("timeupdate", displaySubtitle);
      return () => {
        videoElement.removeEventListener("timeupdate", displaySubtitle);
      };
    }
  }, [adjustedSubtitles, playMode, lastSubtitle, playbackSpeed]);

  const handleSubtitleClick = (startTime) => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTime;
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowRight") handleVideoSeek("forward");
      if (e.key === "ArrowLeft") handleVideoSeek("backward");
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [replayType, subtitles]);

  useEffect(() => {
    if (
      highlightedSubtitle !== null &&
      subtitleRefs.current[highlightedSubtitle]
    ) {
      subtitleRefs.current[highlightedSubtitle].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [highlightedSubtitle]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const dialectMap = {
    hob: "Hokkaidō-ben (北海道弁)",
    ksb: "Kansai-ben (関西弁)",
    ktb: "Kantō-ben (関東弁)",
    kyb: "Kyōto-ben (京都弁)",
    kyu: "Kyūshū-ben (九州弁)",
    nab: "Nagano-ben (長野弁)",
    osb: "Ōsaka-ben (大阪弁)",
    rkb: "Rikuchū-ben (陸中弁)",
    thb: "Tōhoku-ben (東北弁)",
    tsb: "Tosa-ben (土佐弁)",
    tsug: "Tsugaru-ben (津軽弁)",
  };

  const handleInputSubmit = async () => {
    if (selectedLanguage === "japanese") {
      let trimmedInputValue = inputValue.trim();
      // Detect if the input is in Romaji
      if (/^[a-zA-Z]+$/.test(trimmedInputValue)) {
        trimmedInputValue = toHiragana(trimmedInputValue);
      }
      const hiraganaInputValue = toHiragana(trimmedInputValue);
      const katakanaInputValue = toKatakana(trimmedInputValue);

      try {
        const response = await fetch(`${apiUrl}/dictionaries/lookup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            word: trimmedInputValue,
            hiragana: hiraganaInputValue,
            katakana: katakanaInputValue,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const { entries } = await response.json();

        if (entries.length > 0) {
          let currentIndex = 0;

          const showEntry = (index) => {
            const entry = entries[index];
            const kanjiText = entry.kanji.map((k) => k.text).join(", ");
            const kanaText = entry.kana.map((k) => k.text).join(", ");
            const furigana =
              kanjiText && kanaText
                ? `${kanjiText}, ${kanaText}`
                : kanjiText || kanaText;
            const meanings = entry.sense
              .map(
                (sense, senseIndex) =>
                  `<b>${senseIndex + 1}:</b> (${
                    sense.partOfSpeech ? sense.partOfSpeech.join(", ") : "n/a"
                  }) ${sense.gloss.map((g) => g.text).join(", ")}`
              )
              .join("<br>");
            const dialects = entry.sense
              .filter((sense) => sense.dialect && sense.dialect.length > 0)
              .map((sense) =>
                sense.dialect.map((d) => dialectMap[d] || d).join(", ")
              )
              .join(", ");

            Swal.fire({
              html: `
              <section class="decksectionpopup">
                <form id="signupForm">
                  <h1 class="deckinfotitle" style="font-size: 24px;">${trimmedInputValue}</h1>
                  ${
                    furigana
                      ? `<h2 class="deckinfofurigana" style="color: white; font-size: 20px;">${furigana}</h2>`
                      : ""
                  }
                  ${
                    dialects
                      ? `<h3 class="deckinfodialect" style="color: white; font-size: 16px;">Dialect: ${dialects}</h3>`
                      : ""
                  }
                  <h4 class="deckinfodescription" style="font-size: 18px;">${meanings}</h4>
                  <br>
                  ${
                    entries.length > 1
                      ? `<div style="display: flex; justify-content: space-between;">
                          <button type="button" id="prevButton" class="swal2-styled" style="color: white;">Previous</button>
                          <button type="button" id="nextButton" class="swal2-styled" style="color: white;">Next</button>
                        </div>`
                      : ""
                  }
                  <style>
                    #newCardsLabel, #setnewcards {
                      display: inline-block;
                    }
                  </style>
                  <button class="signupbutton" id="cancelbutton" type="submit">Close</button>
                </form>
              </section>
            `,
              showConfirmButton: false,
              allowOutsideClick: true,
              customClass: {
                popup: "deckbodypopup",
                content: "decksectionpopup",
              },
              didOpen: () => {
                document
                  .getElementById("cancelbutton")
                  .addEventListener("click", (event) => {
                    event.preventDefault();
                    Swal.close();
                  });

                if (entries.length > 1) {
                  document
                    .getElementById("prevButton")
                    .addEventListener("click", (event) => {
                      event.preventDefault();
                      currentIndex =
                        (currentIndex - 1 + entries.length) % entries.length;
                      showEntry(currentIndex);
                    });

                  document
                    .getElementById("nextButton")
                    .addEventListener("click", (event) => {
                      event.preventDefault();
                      currentIndex = (currentIndex + 1) % entries.length;
                      showEntry(currentIndex);
                    });
                }
              },
            });
          };

          showEntry(currentIndex);
        } else {
          Swal.fire({
            html: `
          <section class="decksectionpopup">
          <form id="signupForm">
            <h1 class="deckinfotitle">Oops...</h1>
            
            <h4 class="deckinfodescription">Word not found!</h4>
            <br>
            <style>
              #newCardsLabel, #setnewcards {
                display: inline-block;
              }
            </style>

            <button class="signupbutton" id="cancelbutton" type="submit">Close</button>
          </form>
          </section>
          `,
            showConfirmButton: false,
            allowOutsideClick: true,
            customClass: {
              popup: "deckbodypopup",
              content: "decksectionpopup",
            },

            didOpen: () => {
              document
                .getElementById("cancelbutton")
                .addEventListener("click", (event) => {
                  event.preventDefault();

                  Swal.close();
                });
            },
          });
        }
      } catch (error) {
        console.error("Error fetching dictionary data:", error);
        Swal.fire({
          title: "Error",
          text: "There was an error fetching the dictionary data.",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: inputValue,
      });
    }
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      handleInputSubmit();
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen pt-24 md:pl-48">
      {!videoSrc && (
        <div
          {...getRootProps({
            className:
              "flex items-center justify-center w-full max-w-xl h-48 p-6 border-2 border-dashed border-gray-400 bg-gray-800 text-gray-100 cursor-pointer text-center mt-8 text-xl",
          })}
        >
          <input {...getInputProps()} />
          <p>Drag & drop a video here, or click to select a file</p>
        </div>
      )}
      {videoSrc && (
        <div className="flex flex-col md:flex-row w-full max-w-8xl mt-6 relative">
          <div className="w-full md:w-3/4 relative">
            <video ref={videoRef} className="w-full h-auto rounded-lg" controls>
              <source src={videoSrc} />
              Your browser does not support the video tag.
            </video>

            {currentSubtitle && (
              <div
                ref={subtitleOverlayRef}
                className="absolute bottom-20 w-full text-center text-white bg-black bg-opacity-50 p-3 text-xl select-text pointer-events-auto"
                style={{ display: areSubsVisible ? "block" : "none" }} // Toggle visibility
                dangerouslySetInnerHTML={{ __html: currentSubtitle }}
              ></div>
            )}
          </div>
          <div
            className="w-full md:w-1/5 bg-gray-900 text-white p-5 mt-4 md:mt-0 md:ml-4 rounded-lg flex flex-col"
            style={{ height: videoHeight }}
          >
            <div className="flex justify-around mb-4">
              <button
                onClick={() => setActiveTab("settings")}
                className={`py-2 px-4 rounded ${
                  activeTab === "settings"
                    ? "bg-gray-100 text-black"
                    : "bg-gray-800 text-white hover:bg-gray-700 transition-all duration-300"
                }`}
              >
                Settings
              </button>
              <button
                onClick={() => setActiveTab("track")}
                className={`py-2 px-4 rounded ${
                  activeTab === "track"
                    ? "bg-gray-100 text-black"
                    : "bg-gray-800 text-white hover:bg-gray-700 transition-all duration-300"
                }`}
              >
                Track
              </button>
            </div>
            {activeTab === "settings" && (
              <div
                className="overflow-y-auto h-full pr-2"
                style={{
                  maxHeight: `${videoHeight - 100}px`,
                  paddingRight: "4px",
                  paddingLeft: "4px",
                }}
              >
                <div
                  {...getSubtitleRootProps({
                    className:
                      "flex items-center justify-center w-full h-24 p-4 border-2 border-dashed border-gray-400 bg-gray-800 text-gray-100 cursor-pointer text-center text-lg",
                  })}
                >
                  <input {...getSubtitleInputProps()} />
                  <p>Drop .srt or .vtt subtitles or click to select</p>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="subtitle-offset"
                    className="block text-white mb-2 text-lg"
                  >
                    Subtitle Offset (ms):
                  </label>
                  <input
                    type="number"
                    id="subtitle-offset"
                    value={subtitleOffset}
                    onChange={handleOffsetChange}
                    className="w-full p-3 rounded bg-gray-800 text-white border border-gray-400 text-lg"
                  />
                </div>
                <div className="mt-4 flex space-x-4">
                  <button
                    onClick={clearVideo}
                    className="w-full py-2 px-4 rounded bg-gray-800 text-white hover:bg-gray-700 transition-all duration-300"
                  >
                    Clear Video
                  </button>
                  <button
                    onClick={clearSubtitles}
                    className="w-full py-2 px-4 rounded bg-gray-800 text-white hover:bg-gray-700 transition-all duration-300"
                  >
                    Remove Subtitles
                  </button>
                </div>
                <div className="mt-4">
                  <label className="block text-white mb-2 text-lg">
                    Play Mode:
                  </label>
                  <div className="grid grid-cols-2 gap-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        id="playNormally"
                        name="playMode"
                        value="normal"
                        checked={playMode === "normal"}
                        onChange={handlePlayModeChange}
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }} // same shade as settings background
                      />
                      <span className="text-white text-lg">Normal</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        id="pauseAtSubtitle"
                        name="playMode"
                        value="pause"
                        checked={playMode === "pause"}
                        onChange={handlePlayModeChange}
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }} // same shade as settings background
                      />
                      <span className="text-white text-lg">Pause</span>
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-white mb-2 text-lg">
                    Hide Subtitles:
                  </label>
                  <div className="grid grid-cols-2 gap-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        id="hideSubsOff"
                        name="hideSubs"
                        value="off"
                        checked={areSubsVisible === true}
                        onChange={() => setAreSubsVisible(true)} // Show subtitles
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }}
                      />
                      <span className="text-white text-lg">Off</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        id="hideSubsOn"
                        name="hideSubs"
                        value="on"
                        checked={areSubsVisible === false}
                        onChange={() => setAreSubsVisible(false)} // Hide subtitles
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }}
                      />
                      <span className="text-white text-lg">On</span>
                    </label>
                  </div>
                </div>

                <div className="mt-4">
                  <label className="block text-white mb-2 text-lg">
                    Speed Without Subtitles:
                  </label>
                  <div className="grid grid-cols-2 gap-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="playbackSpeed"
                        value={1}
                        checked={playbackSpeed === 1}
                        onChange={handlePlaybackSpeedChange}
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }} // same shade as settings background
                      />
                      <span className="text-white text-lg">1x</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="playbackSpeed"
                        value={2}
                        checked={playbackSpeed === 2}
                        onChange={handlePlaybackSpeedChange}
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }} // same shade as settings background
                      />
                      <span className="text-white text-lg">2x</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="playbackSpeed"
                        value={3}
                        checked={playbackSpeed === 3}
                        onChange={handlePlaybackSpeedChange}
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }} // same shade as settings background
                      />
                      <span className="text-white text-lg">3x</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="playbackSpeed"
                        value={4}
                        checked={playbackSpeed === 4}
                        onChange={handlePlaybackSpeedChange}
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }} // same shade as settings background
                      />
                      <span className="text-white text-lg">4x</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="playbackSpeed"
                        value={6}
                        checked={playbackSpeed === 6}
                        onChange={handlePlaybackSpeedChange}
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }} // same shade as settings background
                      />
                      <span className="text-white text-lg">6x</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="playbackSpeed"
                        value={8}
                        checked={playbackSpeed === 8}
                        onChange={handlePlaybackSpeedChange}
                        className="form-radio"
                        style={{ accentColor: "#1F2937" }} // same shade as settings background
                      />
                      <span className="text-white text-lg">8x</span>
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-white mb-2 text-lg">
                    Dictionary Lookup (Beta):
                  </label>
                  <div className="flex items-center space-x-2 mb-2">
                    <select
                      value={selectedLanguage}
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                      className="p-2 rounded bg-gray-800 text-white border border-gray-400"
                    >
                      <option value="japanese">Japanese</option>
                    </select>
                    <button
                      onClick={handleInputSubmit}
                      className="py-2 px-4 rounded bg-gray-800 text-white hover:bg-gray-700 transition-all duration-300"
                    >
                      Search
                    </button>
                  </div>
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleInputKeyPress}
                    className="w-full p-2 rounded bg-gray-800 text-white border border-gray-400"
                    placeholder="Enter text"
                  />
                </div>
              </div>
            )}

            {activeTab === "track" && (
              <div className="text-left text-white h-full flex-1 overflow-hidden">
                <div
                  className="overflow-y-auto h-full pr-2"
                  style={{
                    paddingRight: "4px",
                    paddingLeft: "4px",
                    maxHeight: `${videoHeight - 100}px`,
                  }}
                >
                  {adjustedSubtitles.map((subtitle, index) => (
                    <div
                      key={index}
                      ref={(el) => (subtitleRefs.current[index] = el)}
                      className={`flex justify-between items-center p-2 m-2 border rounded cursor-pointer ${
                        currentTime >= subtitle.startTime * 1000 &&
                        currentTime <= subtitle.endTime * 1000
                          ? "bg-gray-700 border-gray-300"
                          : "border-gray-600"
                      } hover:bg-gray-700`}
                      onClick={() => handleSubtitleClick(subtitle.startTime)}
                    >
                      <p className="text-lg flex-grow">{subtitle.text}</p>
                      <span className="text-sm text-gray-400 ml-2">
                        {formatTime(subtitle.startTime)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!videoSrc && (
        <>
          <p className="text-gray-100 mt-4 text-xl">No video selected</p>
          <a
            href="https://youtu.be/6UHjjMg9DMk"
            title="Media player tutorial"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-100 mt-4 text-xl underline"
          >
            Media player tutorial
          </a>
        </>
      )}
    </div>
  );
};

export default Watch;
