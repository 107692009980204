import React from "react";
import Logo from "../Assets/Logo.svg";
import { BsTwitter } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTiktok } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { handleSignup } from "./Navbar";
import { handleLogin } from "./Navbar";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer-wrapper">
      <div className="footer-icons">
        <a
          href="https://www.youtube.com/@sudotoapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsYoutube />
        </a>
        <a
          href="https://www.instagram.com/sudotoapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <RiInstagramFill />
        </a>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <a href="#" onClick={handleSignup}>
            <span>{t("footer.signup")}</span>
          </a>
          <a href="#" onClick={handleLogin}>
            <span>{t("footer.login")}</span>
          </a>
          <a
            href="https://roasted-devourer-fe4.notion.site/30ba495beea04a4fad03337bb1bc07b7?v=ffa5e239e5f34e3a84d362c1418048a9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("footer.roadmap")}</span>
          </a>
          <a
            href="https://roasted-devourer-fe4.notion.site/b96514c560f14657a134fce81b98f7ab?v=38f6be269bef4239ba243240caef19c9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("footer.userguide")}</span>
          </a>
        </div>
        <div className="footer-section-columns">
          <a
            href="https://www.youtube.com/@sudotoapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("footer.youtube")}</span>
          </a>
          <a
            href="https://www.instagram.com/sudotoapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("footer.instagram")}</span>
          </a>
        </div>
        <div className="footer-section-columns">
          <Link to="/tos">
            <span>{t("footer.tos")}</span>
          </Link>
          <Link to="/privacy">
            <span>{t("footer.privacy")}</span>
          </Link>
          <Link to="/support">
            <span>{t("footer.support")}</span>
          </Link>
          <a
            href="https://forms.gle/MEspjyYUNzuzsenR8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("footer.submitfeedback")}</span>
          </a>
          <a href="mailto:support@sudoto.app">
            <span>{t("footer.supportemail")}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

/*<div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>*/
