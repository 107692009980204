// Navbar.js
import { FaFire } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import "./NavbarComponent.css";
import "react-calendar/dist/Calendar.css";

const Navbar = () => {
  /*const [currentStreak, setCurrentStreak] = useState(getStreak());
  const [currentStreakData, setCurrentStreakData] = useState(getStreakData());
  const [currentPoints, setPoints] = useState(getPoints());*/

  /*useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStreak(getStreak());
      setCurrentStreakData(getStreakData());
      setPoints(getPoints());
    }, 500); // Check for updates every second

    return () => clearInterval(interval); // Clean up on unmount
  }, []);*/

  return (
    <div className="navbar">
      <h1 className="title">Sudoto</h1>
      <button id="infopopupbutton" className="iconContainer">
        <FaFire size={40} />
        <span id="streak" className="iconNumber">
          0
        </span>
        <BsStars size={40} />
        <span id="points" className="iconNumber">
          0
        </span>
      </button>
    </div>
  );
};

/*function infoPopup(currentStreak, currentStreakData, currentPoints) {
  Swal.fire({
    html: `
      <section class="decksectionpopup">
        <form id="signupForm">
          <h1 class="deckinfotitle">${currentPoints} total stars</h1>
          <h1 class="deckinfodescription">Collect stars through reviews!</h4>
          
          <br>
          <h1 class="deckinfotitle">${currentStreak} day streak</h4>
          <h1 class="deckinfodescription">Increase your streak with new cards!</h4>
          <br>
          <div id="calendar" ></div>
          <br>
          <style>
            #newCardsLabel, #setnewcards {
              display: inline-block;
            }
          </style>
      
          
          
          
        </form>
      </section>
      `,
    showConfirmButton: false,
    allowOutsideClick: true,
    customClass: {
      popup: "deckbodypopup",
      content: "decksectionpopup",
    },

    didOpen: () => {
      console.log(currentStreakData);
      ReactDOM.render(
        <Calendar
          tileClassName={({ date, view }) =>
            currentStreakData.includes(
              `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
                -2
              )}-${("0" + date.getDate()).slice(-2)}`
            )
              ? "highlight"
              : null
          }
        />,
        document.getElementById("calendar")
      );
    },
  });
}*/

export default Navbar;
