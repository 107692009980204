import "./Signup.css";
import { useState } from "react";
import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import Swal from "sweetalert2";
import { isEmail } from "validator"; // adjust the path as needed

const firebaseConfig = {
  apiKey: "AIzaSyCdXUtqrrtVwTFN-FcnbnqCHCzkfDfK-Zg",
  authDomain: "sudoto-app.firebaseapp.com",
  projectId: "sudoto-app",
  storageBucket: "sudoto-app.appspot.com",
  messagingSenderId: "270438936342",
  appId: "1:270438936342:web:416d57b1aed27b14032a37",
  measurementId: "G-VN2DDSLXQW",
  databaseURL: "https://sudoto-app-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          window.location.href = "/app";
        }
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      if (!isEmail(email)) {
        Swal.fire(
          "Invalid Email",
          "Please enter a valid email address",
          "error"
        );
        return;
      }

      if (password.length < 8) {
        Swal.fire(
          "Invalid Password",
          "Password should be at least 8 characters long",
          "error"
        );
        return;
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        // User's email is not verified

        Swal.fire({
          title: "Please verify your email",
          text: "Check your spam and junk folders",
          icon: "error",
          footer: '<a href="#" id="resend-email">Resend verification email</a>',
          willOpen: () => {
            const resendLink = document.getElementById("resend-email");
            resendLink.addEventListener("click", async (event) => {
              event.preventDefault();
              try {
                await sendEmailVerification(auth.currentUser);
                Swal.fire(
                  "Verication email resent",
                  "Check your spam and junk folders",
                  "success"
                );
              } catch (error) {
                Swal.fire("Error", error.message, "error");
              }
            });
          },
        });
        return;
      }

      window.location.href = "/app";

      // User is logged in and email is verified
    } catch (error) {
      Swal.fire("Error logging in", error.message, "error");
    }
  };

  const resetPass = async (auth, email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      Swal.fire(
        "Email Sent",
        "Please check your email for a password reset link.",
        "success"
      );
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  return (
    <body class="signupbody">
      <section class="signupsection">
        <form onSubmit={handleLogin}>
          <h1 class="h1signup">Log In</h1>
          <h4 class="signuph4">Welcome back to Sudoto!</h4>
          <div class="inputbox">
            <ion-icon name="mail-outline"></ion-icon>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label for="">Email</label>
          </div>
          <div class="inputbox">
            <ion-icon name="lock-closed-outline"></ion-icon>
            <input
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label for="">Password</label>
          </div>

          <div class="forget">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                Swal.fire({
                  title: "Forgot Password",
                  text: "We'll send a password reset email to you:",
                  input: "text",
                  inputPlaceholder: "Enter your email",
                  showCancelButton: true,
                  confirmButtonText: "Send",
                  preConfirm: (email) => {
                    if (!email) {
                      Swal.showValidationMessage(
                        "Please enter your email address"
                      );
                    } else {
                      resetPass(auth, email);
                    }
                  },
                });
              }}
            >
              Forgot Password?
            </a>
          </div>
          <button class="signupbutton" type="submit">
            Continue
          </button>
          <div class="register">
            <p>
              Don't have an account? <a href="/signup">Try Sudoto for free!</a>
            </p>
          </div>
        </form>
      </section>
      <footer class="footer">
        <p>
          <strong>&copy; 2024 Sudoto</strong>
        </p>
      </footer>
    </body>
  );
}

export default LoginPage;
