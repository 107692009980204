import React, { useEffect } from "react";
import Header from "./Components/Header";
import { Helmet } from "react-helmet";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  reload,
} from "firebase/auth";
import { getDatabase, ref, get, set } from "firebase/database";
import { isEmail } from "validator";

import * as Icons from "react-icons/ai";

import "./AppComponents/AppbarComponent.css";

import Swal from "sweetalert2";

//app page imports
import Navbar from "./AppComponents/Navbar";

import Appbar from "./AppComponents/Appbar";
import DeckView from "./AppComponents/DeckView";

const firebaseConfig = {
  apiKey: "AIzaSyCdXUtqrrtVwTFN-FcnbnqCHCzkfDfK-Zg",
  authDomain: "sudoto-app.firebaseapp.com",
  projectId: "sudoto-app",
  storageBucket: "sudoto-app.appspot.com",
  messagingSenderId: "270438936342",
  appId: "1:270438936342:web:416d57b1aed27b14032a37",
  measurementId: "G-VN2DDSLXQW",
  databaseURL: "https://sudoto-app-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const apiUrl =
  window.location.hostname === "localhost" ? "http://localhost:5000" : "/api";

let isEventListenerAdded = false;
let signupButton;
let signupButton2;
let isBasicBool = false;

const AppPage = () => {
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (!user.emailVerified) {
          //e.preventDefault();
          Swal.fire({
            html: `
        <section class="signupsectionpopup">
          <form id="signupForm">
            <h1 class="h1signup">Verify Your Email</h1>
            <h4 class="signuph4">Check spam and junk folders</h4>
            <br>
            <button class="signupbutton" type="submit">Continue</button>
            <div class="register">
              <p>Didn't get an email? <a id="resendEmailLink">Resend email.</a></p>
              <br>
              <p>Typed the wrong email? <a id="logOutLink">Log out.</a></p>
            </div>
          </form>
        </section>
      `,
            showConfirmButton: false,
            allowOutsideClick: false,
            customClass: {
              popup: "signupbodypopup",
              content: "signupsectionpopup",
            },

            didOpen: () => {
              const popup = Swal.getPopup();
              const resendEmailLink = popup.querySelector("#resendEmailLink");
              const logOutButton = popup.querySelector("#logOutLink");
              const signupButton = popup.querySelector(".signupbutton");

              if (!isEventListenerAdded) {
                resendEmailLink.addEventListener("click", function (event) {
                  event.preventDefault();

                  // Get the current user
                  const user = auth.currentUser;

                  sendEmailVerification(user)
                    .then(() => {
                      Swal.showValidationMessage("Verification email sent.");
                    })
                    .catch((error) => {
                      Swal.showValidationMessage(
                        "Error sending verification email."
                      );
                    });
                });

                logOutButton.addEventListener("click", function (event) {
                  event.preventDefault();

                  signOut(auth)
                    .then(() => {
                      window.location.href = "/";
                    })
                    .catch((error) => {
                      Swal.showValidationMessage("Error logging out.");
                    });
                });

                isEventListenerAdded = true;
              }

              signupButton.addEventListener("click", function (event) {
                event.preventDefault();

                const user = auth.currentUser;

                user.reload().then(() => {
                  if (user.emailVerified) {
                    // If the email is verified, close the Swal popup
                    // THIS IS GONAN RELOAD THE WINDOW
                    window.location.reload();
                    Swal.fire({
                      html: `
              <section class="signupsectionpopup">
                <form id="signupForm">
                  <h1 class="h1signup">Account Setup</h1>
                  <h4 class="signuph4">Let's get started</h4>
                  <div class="inputbox">
                  
                      <input type="text" required id="firstname" />
                      
                      <label for="">Display Name</label>
                  </div>
                  <div class="inputbox">
                    <ion-icon name="lock-closed-outline"></ion-icon>
                    <input type="text" required id="username" maxlength="16" oninput="this.value = this.value.toLowerCase().replace(/[^a-z0-9._-]/g, '')" />
                    <label for="">Username</label>
                  </div>
                  <div class="inputbox">
                  
                      <input type="text" required id="langgoal" value="I want to understand a TV show." />
                      
                      <label for="">Language Goal</label>
                  </div>
                  <button class="signupbutton" type="submit">Finish</button>
                  
                </form>
              </section>
            `,
                      showConfirmButton: false,
                      allowOutsideClick: false,
                      customClass: {
                        popup: "signupbodypopup",
                        content: "signupsectionpopup",
                      },

                      didOpen: () => {
                        const popup = Swal.getPopup();

                        signupButton = popup.querySelector(".signupbutton");

                        signupButton.addEventListener(
                          "click",
                          function (event) {
                            event.preventDefault();

                            const db = getDatabase();
                            const user = auth.currentUser;

                            // Get the values of the input fields

                            const username =
                              popup.querySelector("#username").value;
                            const langgoal =
                              popup.querySelector("#langgoal").value;
                            const displayName =
                              popup.querySelector("#firstname").value;

                            if (username.length < 4 || username.length > 16) {
                              Swal.showValidationMessage(
                                "Username must be between 4 and 16 characters."
                              );
                              return;
                            }
                            if (
                              username === "" ||
                              langgoal === "" ||
                              displayName === "" ||
                              username == null ||
                              langgoal == null ||
                              displayName == null
                            ) {
                              Swal.showValidationMessage(
                                "1 or more fields are empty."
                              );
                              return;
                            }

                            user.reload().then(() => {
                              if (user.emailVerified) {
                                // Query the database for the username
                                fetch(`${apiUrl}/rdb/getusernames`, {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                })
                                  .then((response) => response.json())
                                  .then((data) => {
                                    if (data.status === "success") {
                                      let usernames = data.usernames;
                                      console.log(
                                        "Usernames retrieved successfully!",
                                        usernames
                                      );
                                      if (usernames.includes(username)) {
                                        Swal.showValidationMessage(
                                          "Username already exists."
                                        );
                                      } else {
                                        const currentDate = new Date();
                                        const dateJoined =
                                          currentDate.toISOString();

                                        const trialExpiryDate = new Date(
                                          dateJoined
                                        );
                                        trialExpiryDate.setUTCDate(
                                          trialExpiryDate.getUTCDate() + 7
                                        );
                                        const trialExpiry =
                                          trialExpiryDate.toISOString();

                                        fetch(`${apiUrl}/rdb/setuser2`, {
                                          method: "POST",
                                          headers: {
                                            "Content-Type": "application/json",
                                          },
                                          body: JSON.stringify({
                                            uid: user.uid,
                                            email: user.email,
                                            username: username,
                                            displayName: displayName,
                                            dateJoined: dateJoined,
                                            trialExpiry: trialExpiry,
                                            langGoal: langgoal,
                                          }),
                                        })
                                          .then((response) => response.json())
                                          .then((data) => {
                                            console.log(
                                              "Data saved successfully!"
                                            );
                                            document.getElementById(
                                              "displaynamefield"
                                            ).innerText = displayName;
                                            document.getElementById(
                                              "langgoalbox"
                                            ).innerText = langgoal;
                                            document.getElementById(
                                              "usernamefield"
                                            ).innerText = "@" + username;
                                            document.getElementById(
                                              "joinedusername"
                                            ).innerText =
                                              "Joined " +
                                              new Date(
                                                dateJoined
                                              ).toLocaleString("default", {
                                                month: "long",
                                                year: "numeric",
                                              });
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Data could not be saved." + error
                                            );
                                          });

                                        /*fetch(
                                    `${apiUrl}/rdb/setlanggoal2`,
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        uid: user.uid,
                                        langGoal: langgoal,
                                      }),
                                    }
                                  )
                                    .then((response) => response.json())
                                    .then((data) => {
                                      console.log(
                                        "Settings data saved successfully!"
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Settings data could not be saved." +
                                          error
                                      );
                                    });*/
                                        Swal.close();
                                      }
                                    } else {
                                      console.log("No data available");
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });
                              } else {
                                Swal.showValidationMessage(
                                  "Please verify your email first."
                                );
                              }
                            });
                          }
                        );
                      },
                    });
                  } else {
                    Swal.showValidationMessage(
                      "Please verify your email first."
                    );
                  }
                });
              });
            },
          });
        } else if (user.emailVerified) {
          // User is verified, check if Firebase has data
          fetch(`${apiUrl}/rdb/getuserdata`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ uid: user.uid }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === "success") {
                console.log("User data exists.");
                const {
                  dateJoined,
                  displayName,
                  email,
                  langGoal,
                  username,
                  isBasic,
                } = data.userData;

                isBasicBool = isBasic;

                // Find the HTML element with the id 'usernamefield' and set its value to 'username'
                document.getElementById("displaynamefield").innerText =
                  displayName;
                document.getElementById("langgoalbox").innerText = langGoal;
                document.getElementById("usernamefield").innerText =
                  "@" + username;
                document.getElementById("joinedusername").innerText =
                  "Joined " +
                  new Date(dateJoined).toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  });
                document
                  .getElementById("editprofile")
                  .addEventListener("click", function () {
                    Swal.fire({
                      html: `
    <section class="signupsectionpopup">
      <form id="signupForm">
        <h1 class="h1signup">Edit Profile</h1>
        <div class="inputbox">
          <input type="text" value="${displayName}" required id="firstname" />
          <label for="">Display Name</label>
        </div>
        <div class="inputbox">
          <ion-icon name="lock-closed-outline"></ion-icon>
          <input type="text" value="${username}" required id="username" maxlength="16" oninput="this.value = this.value.toLowerCase().replace(/[^a-z0-9._-]/g, '')" />
          <label for="">Username</label>
        </div>
        <div class="inputbox">
          <input type="text" required id="langgoal" value="${langGoal}" />
          <label for="">Language Goal</label>
        </div>
        <button class="signupbutton" type="submit">Update</button>
      </form>
    </section>
  `,
                      showConfirmButton: false,
                      allowOutsideClick: true,
                      customClass: {
                        popup: "signupbodypopup",
                        content: "signupsectionpopup",
                      },
                      didOpen: () => {
                        const form = document.getElementById("signupForm");

                        // Remove existing event listeners
                        const clone = form.cloneNode(true);
                        form.parentNode.replaceChild(clone, form);

                        clone.addEventListener("submit", function (e) {
                          e.preventDefault();

                          const firstname =
                            document.getElementById("firstname").value;
                          const usernameField =
                            document.getElementById("username").value;
                          const langgoal =
                            document.getElementById("langgoal").value;
                          if (usernameField !== username) {
                            console.log("Username has been changed");

                            fetch(`${apiUrl}/rdb/changeuserdata`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                uid: user.uid, // replace with actual uid
                                type: "username",
                                value: usernameField,
                              }),
                            })
                              .then((response) => response.json())
                              .then((data) => {
                                if (data.success) {
                                  console.log(
                                    "Display Name has been updated successfully"
                                  );

                                  document.getElementById(
                                    "usernamefield"
                                  ).innerText = "@" + usernameField;
                                } else {
                                  if (
                                    data.error === "Username already exists."
                                  ) {
                                    Swal.fire({
                                      html: `
    <section class="signupsectionpopup">
      <form id="signupForm">
        <h1 class="text-lg text-white">Username is already taken.</h1>
        <br>
        
        <button id="but" class="signupbutton" type="button">Close</button>
      </form>
    </section>
  `,
                                      showConfirmButton: false,
                                      allowOutsideClick: true,
                                      customClass: {
                                        popup: "signupbodypopup",
                                        content: "signupsectionpopup",
                                      },
                                      didOpen: () => {
                                        const button =
                                          document.getElementById("but");
                                        button.addEventListener(
                                          "click",
                                          (event) => {
                                            event.preventDefault();
                                            Swal.close();
                                          }
                                        );
                                      },
                                    });
                                  } else {
                                    console.error(
                                      "Error occurred during updating Display Name"
                                    );
                                  }
                                }
                              })
                              .catch((error) => {
                                console.error("Error:", error);
                              });
                          }

                          if (firstname !== displayName) {
                            console.log("Display Name has been changed");

                            fetch(`${apiUrl}/rdb/changeuserdata`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                uid: user.uid, // replace with actual uid
                                type: "displayName",
                                value: firstname,
                              }),
                            })
                              .then((response) => response.json())
                              .then((data) => {
                                if (data.success) {
                                  console.log(
                                    "Display Name has been updated successfully"
                                  );
                                  document.getElementById(
                                    "displaynamefield"
                                  ).innerText = firstname;
                                } else {
                                  console.error(
                                    "Error occurred during updating Display Name"
                                  );
                                }
                              })
                              .catch((error) => {
                                console.error("Error:", error);
                              });
                          }

                          if (langgoal !== langGoal) {
                            console.log("Language Goal has been changed");

                            fetch(`${apiUrl}/rdb/changeuserdata`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                uid: user.uid, // replace with actual uid
                                type: "langGoal",
                                value: langgoal,
                              }),
                            })
                              .then((response) => response.json())
                              .then((data) => {
                                if (data.success) {
                                  console.log(
                                    "Display Name has been updated successfully"
                                  );

                                  document.getElementById(
                                    "langgoalbox"
                                  ).innerText = langgoal;
                                } else {
                                  console.error(
                                    "Error occurred during updating Display Name"
                                  );
                                }
                              })
                              .catch((error) => {
                                console.error("Error:", error);
                              });
                          }

                          fetch(`${apiUrl}/rdb/getuserdata`, {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ uid: user.uid }),
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              if (data.status === "success") {
                                console.log("User data exists.");
                                const {
                                  dateJoined,
                                  displayName,
                                  email,
                                  langGoal,
                                  username,
                                  isBasic,
                                } = data.userData;

                                isBasicBool = isBasic;

                                // Find the HTML element with the id 'usernamefield' and set its value to 'username'
                                document.getElementById(
                                  "displaynamefield"
                                ).innerText = displayName;
                                document.getElementById(
                                  "langgoalbox"
                                ).innerText = langGoal;
                                document.getElementById(
                                  "usernamefield"
                                ).innerText = "@" + username;
                                document.getElementById(
                                  "joinedusername"
                                ).innerText =
                                  "Joined " +
                                  new Date(dateJoined).toLocaleString(
                                    "default",
                                    {
                                      month: "long",
                                      year: "numeric",
                                    }
                                  );
                              }
                            });

                          Swal.close();
                        });
                      },
                    });
                  });

                fetch(`${apiUrl}/rdb/get-image/${user.uid}`)
                  .then((response) => response.text())
                  .then((base64Image) => {
                    // Set the src of the image element to the base64 string
                    document.getElementById("userpfp").src =
                      "data:image/jpeg;base64," + base64Image;
                  })
                  .catch((error) => console.error("Error:", error));

                // check the trial expiry date
                fetch(`${apiUrl}/rdb/gettrialexpiry`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ uid: user.uid }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.status === "success") {
                      const trialExpiry = new Date(data.trialExpiry);
                      const currentDate = new Date();
                      console.log("Trial expiry date retrieved successfully.");

                      if (trialExpiry > currentDate || isBasicBool) {
                        console.log("Trial is active.");
                      } else {
                        // trial expired swal

                        Swal.fire({
                          html: `
    <section class="signupsectionpopup">
      <form id="signupForm">
        <h1 class="h1signup">Trial Expired</h1>
        <h4 class="signuph4">Please choose a plan to keep using Sudoto</h4>
        <h4 class="signuph4">Cancel anytime | 14-day refund policy</h4>
        <br>
        <div class="plan-card">
          <h1>✨ Monthly Plan ✨</h1>
          <h2>$5.99 per month</h2>
          <p>• Custom word library</p>
          <p>• AI practice</p>
          <p>• Watch menu</p>
          <p>• Early access features</p>
          <button class="monthlybutton" type="button" data-plan="monthly">Choose Monthly Plan</button>
        </div>
        
        <div class="plan-card">
          <h1>🔥 Annual Plan 🔥</h1>
          <h2>$59.99 per year</h2>
          <p>• All previous benefits</p>
          <p>• 20% more value</p>
          <button class="annuallybutton" type="button" data-plan="annual">Choose Annual Plan</button>
        </div>
        <div class="register">
          <p><a id="nothanks">No thanks</a></p>
        </div>
      </form>
    </section>
  `,
                          showConfirmButton: false,
                          allowOutsideClick: false,
                          customClass: {
                            popup: "signupbodypopup",
                            content: "signupsectionpopup",
                          },
                          didOpen: () => {
                            const noThanksButton =
                              document.getElementById("nothanks");
                            noThanksButton.addEventListener("click", () => {
                              const auth = getAuth();
                              signOut(auth)
                                .then(() => {
                                  window.location.href = "/";
                                })
                                .catch((error) => {
                                  console.error("Sign out failed", error);
                                });
                            });

                            const planButtons =
                              document.querySelectorAll("[data-plan]");
                            planButtons.forEach((button) => {
                              button.addEventListener("click", (event) => {
                                event.preventDefault();
                                const plan = button.getAttribute("data-plan");
                                let stripeLink;
                                if (plan === "monthly") {
                                  stripeLink =
                                    "https://buy.stripe.com/4gw3dL1lXegqdbi9AT";
                                } else if (plan === "annual") {
                                  stripeLink =
                                    "https://buy.stripe.com/4gw01zfcNc8ib3a7sM";
                                }
                                window.location.href = stripeLink;
                              });
                            });
                          },
                        });
                      }
                    } else {
                      console.log("No user data available");
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                console.log("User data does not exist in Firebase.");
                // run swal popup for user init

                // swal start
                Swal.fire({
                  html: `
              <section class="signupsectionpopup">
                <form id="signupForm">
                  <h1 class="h1signup">Account Setup</h1>
                  <h4 class="signuph4">Let's get started</h4>
                  <div class="inputbox">
                  
                      <input type="text" required id="firstname" />
                      
                      <label for="">Display Name</label>
                  </div>
                  <div class="inputbox">
                    <ion-icon name="lock-closed-outline"></ion-icon>
                    <input type="text" required id="username" maxlength="16" oninput="this.value = this.value.toLowerCase().replace(/[^a-z0-9._-]/g, '')" />
                    <label for="">Username</label>
                  </div>
                  <div class="inputbox">
                  
                      <input type="text" required id="langgoal" value="I want to understand a TV show." />
                      
                      <label for="">Language Goal</label>
                  </div>
                  <button class="signupbutton" type="submit">Finish</button>
                  
                </form>
              </section>
            `,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  customClass: {
                    popup: "signupbodypopup",
                    content: "signupsectionpopup",
                  },

                  didOpen: () => {
                    const popup = Swal.getPopup();

                    signupButton = popup.querySelector(".signupbutton");

                    signupButton.addEventListener("click", function (event) {
                      event.preventDefault();

                      const db = getDatabase();
                      const user = auth.currentUser;

                      // Get the values of the input fields

                      const username = popup.querySelector("#username").value;
                      const langgoal = popup.querySelector("#langgoal").value;
                      const displayName =
                        popup.querySelector("#firstname").value;

                      if (username.length < 4 || username.length > 16) {
                        Swal.showValidationMessage(
                          "Username must be between 4 and 16 characters."
                        );
                        return;
                      }
                      if (
                        username === "" ||
                        langgoal === "" ||
                        displayName === "" ||
                        username == null ||
                        langgoal == null ||
                        displayName == null
                      ) {
                        Swal.showValidationMessage(
                          "1 or more fields are empty."
                        );
                        return;
                      }

                      user.reload().then(() => {
                        if (user.emailVerified) {
                          // Query the database for the username
                          fetch(`${apiUrl}/rdb/getusernames`, {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              if (data.status === "success") {
                                let usernames = data.usernames;
                                console.log(
                                  "Usernames retrieved successfully!",
                                  usernames
                                );
                                if (usernames.includes(username)) {
                                  Swal.showValidationMessage(
                                    "Username already exists."
                                  );
                                } else {
                                  const currentDate = new Date();
                                  const dateJoined = currentDate.toISOString();

                                  const trialExpiryDate = new Date(dateJoined);
                                  trialExpiryDate.setUTCDate(
                                    trialExpiryDate.getUTCDate() + 7
                                  );
                                  const trialExpiry =
                                    trialExpiryDate.toISOString();

                                  fetch(`${apiUrl}/rdb/setuser2`, {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                      uid: user.uid,
                                      email: user.email,
                                      username: username,
                                      displayName: displayName,
                                      dateJoined: dateJoined,
                                      trialExpiry: trialExpiry,
                                      langGoal: langgoal,
                                    }),
                                  })
                                    .then((response) => response.json())
                                    .then((data) => {
                                      console.log("Data saved successfully!");
                                      document.getElementById(
                                        "displaynamefield"
                                      ).innerText = displayName;
                                      document.getElementById(
                                        "langgoalbox"
                                      ).innerText = langgoal;
                                      document.getElementById(
                                        "usernamefield"
                                      ).innerText = "@" + username;
                                      document.getElementById(
                                        "joinedusername"
                                      ).innerText =
                                        "Joined " +
                                        new Date(dateJoined).toLocaleString(
                                          "default",
                                          {
                                            month: "long",
                                            year: "numeric",
                                          }
                                        );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Data could not be saved." + error
                                      );
                                    });

                                  /*fetch(
                                    `${apiUrl}/rdb/setlanggoal2`,
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        uid: user.uid,
                                        langGoal: langgoal,
                                      }),
                                    }
                                  )
                                    .then((response) => response.json())
                                    .then((data) => {
                                      console.log(
                                        "Settings data saved successfully!"
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Settings data could not be saved." +
                                          error
                                      );
                                    });*/
                                  Swal.close();
                                }
                              } else {
                                console.log("No data available");
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                        } else {
                          Swal.showValidationMessage(
                            "Please verify your email first."
                          );
                        }
                      });
                    });
                  },
                });

                // end of swal
              }
            })
            .catch((error) => {
              console.error("Error reading data:", error);
            });
        }
        console.log("User is signed in.");
      } else if (!user) {
        window.location.href = "/";
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleSignOut = () => {
    signOut(auth);
    window.location.href = "/";
  };

  return (
    <div className="mainapp">
      <Helmet>
        <title>Sudoto</title>
        <body style="background-color: #23262b" />
      </Helmet>
      <Navbar />
      <DeckView />
      <Appbar />
    </div>
  );
};

export default AppPage;
