import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  signOut,
  getAuth,
} from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCdXUtqrrtVwTFN-FcnbnqCHCzkfDfK-Zg",
  authDomain: "sudoto-app.firebaseapp.com",
  projectId: "sudoto-app",
  storageBucket: "sudoto-app.appspot.com",
  messagingSenderId: "270438936342",
  appId: "1:270438936342:web:416d57b1aed27b14032a37",
  measurementId: "G-VN2DDSLXQW",
  databaseURL: "https://sudoto-app-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);

// Export the functions directly, don't assign them to constants
export {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  signOut,
  getAuth,
  firebaseConfig,
};
