import React from "react";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="bg-white shadow-md rounded-lg max-w-3xl mx-auto p-8">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">
          {t("support.title")}
        </h1>
        <p className="text-lg text-gray-700 mb-4 text-center">
          {t("support.welcome")}
        </p>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.intro")}
          <a href="mailto:support@sudoto.app" className="text-blue-500">
            support@sudoto.app
          </a>
          .
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          {t("support.faqTitle")}
        </h2>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q1")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a1")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q2")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a2")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q3")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a3")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q4")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a4")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q5")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a5")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q6")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a6")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q7")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a7")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q8")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a8")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q9")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a9")}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          {t("support.questions.q10")}
        </h3>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.questions.a10")}
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          {t("support.contactTitle")}
        </h2>
        <p className="text-base leading-relaxed mb-6 text-gray-600">
          {t("support.contactText")}
          <a href="mailto:support@sudoto.app" className="text-blue-500">
            support@sudoto.app
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Support;
