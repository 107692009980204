import React, { useState, useEffect } from "react";
import {
  IoPerson,
  IoJournal,
  IoAlbums,
  IoAddCircle,
  IoHammer,
  IoSettingsSharp,
  IoLibrary,
} from "react-icons/io5";
import { RiBookOpenFill } from "react-icons/ri";
import { PiTelevisionSimpleFill } from "react-icons/pi";
import "./AppbarComponent.css";
import { FaUserCircle } from "react-icons/fa";

const Appbar = () => {
  const [selectedItem, setSelectedItem] = useState("Decks"); // Set initial state to "Decks"

  const handleClick = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    const deckViewElement = document.getElementById("deckview");
    const practiceViewElement = document.getElementById("practiceview");
    const libraryViewElement = document.getElementById("libraryview");
    const watchViewElement = document.getElementById("watchview");
    const settingsViewElement = document.getElementById("settingsview");

    if (selectedItem === "Decks") {
      deckViewElement.style.display = "block";
      practiceViewElement.style.display = "none";
      libraryViewElement.style.display = "none";
      watchViewElement.style.display = "none";
      settingsViewElement.style.display = "none";
    } else if (selectedItem === "Practice") {
      deckViewElement.style.display = "none";
      libraryViewElement.style.display = "none";
      watchViewElement.style.display = "none";
      settingsViewElement.style.display = "none";
      practiceViewElement.style.display = "block";
    } else if (selectedItem === "Library") {
      deckViewElement.style.display = "none";
      practiceViewElement.style.display = "none";
      watchViewElement.style.display = "none";
      settingsViewElement.style.display = "none";
      libraryViewElement.style.display = "block";
    } else if (selectedItem === "Watch") {
      deckViewElement.style.display = "none";
      libraryViewElement.style.display = "none";
      practiceViewElement.style.display = "none";
      settingsViewElement.style.display = "none";
      watchViewElement.style.display = "block";
    } else if (selectedItem === "Profile") {
      deckViewElement.style.display = "none";
      libraryViewElement.style.display = "none";
      watchViewElement.style.display = "none";
      practiceViewElement.style.display = "none";
      settingsViewElement.style.display = "block";
    }
  }, [selectedItem]);

  const navItems = [
    { name: "Practice", icon: <IoHammer />, className: "add-card" },
    { name: "Library", icon: <RiBookOpenFill />, className: "library" },
    { name: "Decks", icon: <IoAlbums />, className: "decks" },
    { name: "Watch", icon: <PiTelevisionSimpleFill />, className: "watch" },
    { name: "Profile", icon: <FaUserCircle />, className: "settings" },
  ];

  return (
    <div className="appbar">
      <nav className="navbar2">
        <ul className="navbar-nav">
          {navItems.map((item, index) => (
            <li
              className={`nav-item ${
                item.name === selectedItem ? "selected" : ""
              } ${item.className}`}
              onClick={() => handleClick(item.name)}
              key={index}
            >
              {item.icon}
              <span className="nav-text">{item.name}</span>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Appbar;
