import "./Signup.css";
import { useState } from "react";
import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import Swal from "sweetalert2";
import { isEmail } from "validator"; // adjust the path as needed

const firebaseConfig = {
  apiKey: "AIzaSyCdXUtqrrtVwTFN-FcnbnqCHCzkfDfK-Zg",
  authDomain: "sudoto-app.firebaseapp.com",
  projectId: "sudoto-app",
  storageBucket: "sudoto-app.appspot.com",
  messagingSenderId: "270438936342",
  appId: "1:270438936342:web:416d57b1aed27b14032a37",
  measurementId: "G-VN2DDSLXQW",
  databaseURL: "https://sudoto-app-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function SignupPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          window.location.href = "/app";
        }
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleSignup = async (event) => {
    event.preventDefault();

    try {
      if (!isEmail(email)) {
        Swal.fire(
          "Invalid Email",
          "Please enter a valid email address",
          "error"
        );
        return;
      }

      if (password.length < 8) {
        Swal.fire(
          "Invalid Password",
          "Password should be at least 8 characters long",
          "error"
        );
        return;
      }

      const credentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      sendEmailVerification(auth.currentUser)
        .then(() => {
          signOut(auth);
          // Verification email sent.
          Swal.fire(
            "Verification email sent",
            "Please verify, then log in - Check your spam and junk folders",
            "success"
          ).finally(() => {
            window.location.href = "/login";
          });
        })
        .catch((error) => {
          Swal.fire("Error signing up", error.message, "error");
        });
    } catch (error) {
      Swal.fire("Error signing up", error.message, "error");
    }
  };

  return (
    <body class="signupbody">
      <section class="signupsection">
        <form onSubmit={handleSignup}>
          <h1 class="h1signup">Sign Up</h1>
          <h4 class="signuph4">14-day free trial</h4>
          <h4 class="signuph4">No credit card required</h4>
          <div class="inputbox">
            <ion-icon name="mail-outline"></ion-icon>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label for="">Email</label>
          </div>
          <div class="inputbox">
            <ion-icon name="lock-closed-outline"></ion-icon>
            <input
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label for="">Password</label>
          </div>

          <button class="signupbutton" type="submit">
            Continue
          </button>
          <div class="register">
            <p>
              Already have an account? <a href="/login">Login here.</a>
            </p>
          </div>
        </form>
      </section>
      <footer class="footer">
        <p>
          <strong>&copy; 2024 Sudoto</strong>
        </p>
      </footer>
    </body>
  );
}

export default SignupPage;
