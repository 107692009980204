import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  signOut,
  getAuth,
} from "./firebase";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AppPage from "./AppPage";
import SignupPage from "./SignupPage";
import LoginPage from "./LoginPage";
import BuyPage from "./BuyPage";
import LogoutPage from "./LogoutPage";
import TOS from "./TOS";
import PrivacyPolicy from "./PrivacyPolicy";
import Support from "./Support";
import LearnPage from "./AppComponents/Learn";

import Swal from "sweetalert2";

function Main() {
  const auth = getAuth();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          window.location.href = "/app";
        }
      }
    });
  }, [auth]);
  return (
    <div className="App">
      <Home />
      <About />
      <Work />
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/app" element={<AppPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Support />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
