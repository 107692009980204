import React, { useEffect } from "react";
import Header from "./Components/Header";
import { Helmet } from "react-helmet";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { getDatabase, ref, get, set } from "firebase/database";
import { isEmail } from "validator";

import * as Icons from "react-icons/ai";

import Swal from "sweetalert2";

const firebaseConfig = {
  apiKey: "AIzaSyCdXUtqrrtVwTFN-FcnbnqCHCzkfDfK-Zg",
  authDomain: "sudoto-app.firebaseapp.com",
  projectId: "sudoto-app",
  storageBucket: "sudoto-app.appspot.com",
  messagingSenderId: "270438936342",
  appId: "1:270438936342:web:416d57b1aed27b14032a37",
  measurementId: "G-VN2DDSLXQW",
  databaseURL: "https://sudoto-app-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

let isEventListenerAdded = false;
let signupButton;
let signupButton2;

const BuyPage = () => {
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (!user.emailVerified) {
          //e.preventDefault();
          Swal.fire({
            html: `
        <section class="signupsectionpopup">
          <form id="signupForm">
            <h1 class="h1signup">Verify Your Email</h1>
            <h4 class="signuph4">Check spam and junk folders</h4>
            <br>
            <button class="signupbutton" type="submit">Continue</button>
            <div class="register">
              <p>Didn't get an email? <a id="resendEmailLink">Resend email.</a></p>
            </div>
          </form>
        </section>
      `,
            showConfirmButton: false,
            allowOutsideClick: false,
            customClass: {
              popup: "signupbodypopup",
              content: "signupsectionpopup",
            },

            didOpen: () => {
              const popup = Swal.getPopup();
              const resendEmailLink = popup.querySelector("#resendEmailLink");
              const signupButton = popup.querySelector(".signupbutton");

              if (!isEventListenerAdded) {
                resendEmailLink.addEventListener("click", function (event) {
                  event.preventDefault();

                  // Get the current user
                  const user = auth.currentUser;

                  sendEmailVerification(user)
                    .then(() => {
                      Swal.showValidationMessage("Verification email sent.");
                    })
                    .catch((error) => {
                      Swal.showValidationMessage(
                        "Error sending verification email."
                      );
                    });
                });

                isEventListenerAdded = true;
              }

              signupButton.addEventListener("click", function (event) {
                event.preventDefault();

                const user = auth.currentUser;

                user.reload().then(() => {
                  if (user.emailVerified) {
                    // If the email is verified, close the Swal popup
                    Swal.close();
                    Swal.fire({
                      html: `
                  <section class="signupsectionpopup">
                    <form id="signupForm">
                      <h1 class="h1signup">Account Setup</h1>
                      <h4 class="signuph4">Let's get started</h4>
                      <div class="inputbox">
                      
                          <input type="text" required id="firstname" />
                          
                          <label for="">Display Name</label>
                      </div>
                      <div class="inputbox">
                        <ion-icon name="lock-closed-outline"></ion-icon>
                        <input type="text" required id="username" maxlength="16" oninput="this.value = this.value.toLowerCase().replace(/[^a-z0-9._-]/g, '')" />
                        <label for="">Username</label>
                      </div>
                      <button class="signupbutton" type="submit">Finish</button>
                      
                    </form>
                  </section>
                `,
                      showConfirmButton: false,
                      allowOutsideClick: false,
                      customClass: {
                        popup: "signupbodypopup",
                        content: "signupsectionpopup",
                      },

                      didOpen: () => {
                        const popup = Swal.getPopup();

                        signupButton2 = popup.querySelector(".signupbutton");

                        signupButton2.addEventListener(
                          "click",
                          function (event) {
                            event.preventDefault();

                            const db = getDatabase();
                            const user = auth.currentUser;

                            // Get the values of the input fields
                            const username =
                              popup.querySelector("#username").value;
                            const displayName =
                              popup.querySelector("#firstname").value;

                            user.reload().then(() => {
                              if (user.emailVerified) {
                                // Query the database for the username
                                const usernameRef = ref(db, "users/");
                                get(usernameRef)
                                  .then((snapshot) => {
                                    if (snapshot.exists()) {
                                      let userData = snapshot.val();
                                      let usernames = Object.values(
                                        userData
                                      ).map((user) => user.username);
                                      if (usernames.includes(username)) {
                                        Swal.showValidationMessage(
                                          "Username already exists."
                                        );
                                      } else {
                                        const currentDate = new Date();
                                        const dateJoined =
                                          currentDate.toISOString();

                                        const trialExpiryDate = new Date(
                                          dateJoined
                                        );
                                        trialExpiryDate.setUTCDate(
                                          trialExpiryDate.getUTCDate() + 14
                                        );
                                        const trialExpiry =
                                          trialExpiryDate.toISOString();

                                        set(ref(db, "users/" + user.uid), {
                                          email: user.email,
                                          username: username,
                                          displayName: displayName,
                                          isPaying: false,
                                          isPremium: false,
                                          dateJoined: dateJoined,
                                          trialExpiry: trialExpiry,
                                        })
                                          .then(() => {
                                            console.log(
                                              "Data saved successfully!"
                                            );
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Data could not be saved." + error
                                            );
                                          });
                                        Swal.close();
                                      }
                                    } else {
                                      console.log("No data available");
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });
                              } else {
                                Swal.showValidationMessage(
                                  "Please verify your email first."
                                );
                              }
                            });
                          }
                        );
                      },
                    });
                  } else {
                    Swal.showValidationMessage(
                      "Please verify your email first."
                    );
                  }
                });
              });
            },
          });
        } else if (user.emailVerified) {
          // User is verified, check if Firebase has data
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}`);

          get(userRef)
            .then((snapshot) => {
              if (snapshot.exists()) {
                console.log("User data exists in Firebase.");
                // check the trial expiry date
                const db = getDatabase(app);
                const userRef = ref(db, "users/" + user.uid);
                get(userRef)
                  .then((snapshot) => {
                    if (snapshot.exists()) {
                      const userData = snapshot.val();
                      const trialExpiry = new Date(userData.trialExpiry);
                      const currentDate = new Date();

                      if (trialExpiry > currentDate) {
                        console.log("Trial is active.");
                      } else {
                        // trial expired swal

                        Swal.fire({
                          html: `
                            <section class="signupsectionpopup">
                              <form id="signupForm">
                                <h1 class="h1signup">Trial Expired</h1>
                                <h4 class="signuph4">Please choose a plan to keep using Sudoto</h4>
                                <br>
                                <button class="signupbutton" type="submit">Continue</button>
                                <div class="register">
                                <p><a id="nothanks">No thanks</a></p>
                                </div>
                              </form>
                            </section>
                          `,
                          showConfirmButton: false,
                          allowOutsideClick: false,
                          customClass: {
                            popup: "signupbodypopup",
                            content: "signupsectionpopup",
                          },
                          didOpen: () => {
                            const noThanksButton =
                              document.getElementById("nothanks");
                            noThanksButton.addEventListener("click", () => {
                              const auth = getAuth();
                              signOut(auth)
                                .then(() => {
                                  window.location.href = "/";
                                })
                                .catch((error) => {
                                  console.error("Sign out failed", error);
                                });
                            });

                            const continueButton =
                              document.querySelector(".signupbutton");
                            continueButton.addEventListener(
                              "click",
                              (event) => {
                                event.preventDefault();
                                window.location.href = "/buy";
                              }
                            );
                          },
                        });
                      }
                    } else {
                      console.log("No user data available");
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                console.log("User data does not exist in Firebase.");
                // run swal popup for user init

                // swal start
                Swal.fire({
                  html: `
              <section class="signupsectionpopup">
                <form id="signupForm">
                  <h1 class="h1signup">Account Setup</h1>
                  <h4 class="signuph4">Let's get started</h4>
                  <div class="inputbox">
                  
                      <input type="text" required id="firstname" />
                      
                      <label for="">Display Name</label>
                  </div>
                  <div class="inputbox">
                    <ion-icon name="lock-closed-outline"></ion-icon>
                    <input type="text" required id="username" maxlength="16" oninput="this.value = this.value.toLowerCase().replace(/[^a-z0-9._-]/g, '')" />
                    <label for="">Username</label>
                  </div>
                  <button class="signupbutton" type="submit">Finish</button>
                  
                </form>
              </section>
            `,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  customClass: {
                    popup: "signupbodypopup",
                    content: "signupsectionpopup",
                  },

                  didOpen: () => {
                    const popup = Swal.getPopup();

                    signupButton = popup.querySelector(".signupbutton");

                    signupButton.addEventListener("click", function (event) {
                      event.preventDefault();

                      const db = getDatabase();
                      const user = auth.currentUser;

                      // Get the values of the input fields
                      const username = popup.querySelector("#username").value;
                      const displayName =
                        popup.querySelector("#firstname").value;

                      user.reload().then(() => {
                        if (user.emailVerified) {
                          // Query the database for the username
                          const usernameRef = ref(db, "users/");
                          get(usernameRef)
                            .then((snapshot) => {
                              if (snapshot.exists()) {
                                let userData = snapshot.val();
                                let usernames = Object.values(userData).map(
                                  (user) => user.username
                                );
                                if (usernames.includes(username)) {
                                  Swal.showValidationMessage(
                                    "Username already exists."
                                  );
                                } else {
                                  const currentDate = new Date();
                                  const dateJoined = currentDate.toISOString();

                                  const trialExpiryDate = new Date(dateJoined);
                                  trialExpiryDate.setUTCDate(
                                    trialExpiryDate.getUTCDate() + 14
                                  );
                                  const trialExpiry =
                                    trialExpiryDate.toISOString();

                                  set(ref(db, "users/" + user.uid), {
                                    email: user.email,
                                    username: username,
                                    displayName: displayName,
                                    isPaying: false,
                                    isPremium: false,
                                    dateJoined: dateJoined,
                                    trialExpiry: trialExpiry,
                                  })
                                    .then(() => {
                                      console.log("Data saved successfully!");
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Data could not be saved." + error
                                      );
                                    });
                                  Swal.close();
                                }
                              } else {
                                console.log("No data available");
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                        } else {
                          Swal.showValidationMessage(
                            "Please verify your email first."
                          );
                        }
                      });
                    });
                  },
                });

                // end of swal
              }
            })
            .catch((error) => {
              console.error("Error reading data from Firebase:", error);
            });
        }
        console.log("User is signed in.");
      } else if (!user) {
        window.location.href = "/";
      }
    });
  }, [auth]);

  const handleSignOut = () => {
    signOut(auth);
    window.location.href = "/";
  };

  return (
    <div className="mainapp">
      <Helmet>
        <title>Sudoto</title>
      </Helmet>
      <Header />
      <h1>This is the App page</h1>
      <button onClick={handleSignOut}>Sign Out</button>
    </div>
  );
};

export default BuyPage;
