import React, { useState, useEffect } from "react";
import { IoAddCircle } from "react-icons/io5";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoCloseCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MdUpload } from "react-icons/md";
import { FaMicrophone } from "react-icons/fa";
import { IoMdPlayCircle } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import "@fortawesome/fontawesome-free/css/all.css";
import "./DeckViewComponent.css";
import langlist from "./langlist.json";
import ImageUpload from "./ImageComponent";
import { IoMdAddCircle } from "react-icons/io";
import DictionaryPopup from "./DictionaryPopup";
import Watch from "./Watch";

import OpenAI from "openai";

import Swal from "sweetalert2";

import ReactDOM from "react-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

// firebase
import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
  updateEmail,
} from "firebase/auth";
import {
  getDatabase,
  ref,
  get,
  set,
  onValue,
  update,
  remove,
} from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCdXUtqrrtVwTFN-FcnbnqCHCzkfDfK-Zg",
  authDomain: "sudoto-app.firebaseapp.com",
  projectId: "sudoto-app",
  storageBucket: "sudoto-app.appspot.com",
  messagingSenderId: "270438936342",
  appId: "1:270438936342:web:416d57b1aed27b14032a37",
  measurementId: "G-VN2DDSLXQW",
  databaseURL: "https://sudoto-app-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const apiUrl =
  window.location.hostname === "localhost" ? "http://localhost:5000" : "/api";

const DeckView = () => {
  const [deckNames, setDeckNames] = useState([]);

  const [user, setUser] = useState(null);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageUpload = (src) => {
    setImageSrc(src);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (currentUser) => {
      setUser(currentUser);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      GetDecks(user, setDeckNames);
    }
  }, [user]);

  let mediaRecorder = null;
  let audioChunks = [];
  let globalAudioURL = {};
  let audioPath = "";
  let sentenceAudioPath = "";

  const handleAudioRecord = (audioId, recordButtonClass) => {
    const audioInput = document.getElementById(audioId);
    const recordButton = document.querySelector(`.${recordButtonClass}`);

    if (recordButton.getAttribute("data-recording") === "true") {
      mediaRecorder.stop();
      mediaRecorder = null;
      recordButton.setAttribute("data-recording", "false");
      recordButton.style.background = "";
    } else {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const audioTracks = stream.getAudioTracks();
          if (audioTracks.length > 0) {
            audioTracks[0].onended = function () {
              console.log("Audio track ended");
            };

            mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.start();
            recordButton.style.background = "red";
            recordButton.setAttribute("data-recording", "true");

            mediaRecorder.ondataavailable = (e) => {
              audioChunks.push(e.data);
            };

            mediaRecorder.onstop = () => {
              const audioBlob = new Blob(audioChunks, {
                type: "audio/ogg; codecs=opus",
              });
              const audioFile = new File([audioBlob], "recording.ogg", {
                type: "audio/ogg; codecs=opus",
              });
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(audioFile);
              audioInput.files = dataTransfer.files;
              audioChunks = []; // Clear the audio chunks
              changePlayback(audioId); // Manually trigger playback
              stream.getTracks().forEach((track) => track.stop()); // Clean up the stream
            };
          } else {
            console.error("No audio tracks available");
          }
        })
        .catch((error) => {
          console.error("Error accessing the microphone", error);
        });
    }
  };

  const changePlayback = (audioId) => {
    const audioInput = document.getElementById(audioId);
    const file = audioInput.files[0];
    const audioPlayer = document.getElementById(audioId + "-player");

    if (file) {
      if (file.size <= 548576) {
        // Ensure file is less than 1MB
        globalAudioURL[audioId] = URL.createObjectURL(file);
        audioPlayer.src = globalAudioURL[audioId]; // Set the source of the audio player
        console.log("Audio URL changed:", globalAudioURL[audioId]); // Print the new audio URL
        if (audioId === "audio") {
          audioPath = globalAudioURL[audioId];
        } else if (audioId === "sentenceaudio") {
          sentenceAudioPath = globalAudioURL[audioId];
        }
      } else {
        alert(
          "The file size should be under 1MB. Please select a smaller file."
        );
      }
    } else {
      alert("No file selected.");
    }
  };

  const handlePlayAudio = (audioId) => {
    const audioPlayer = document.getElementById(audioId + "-player");
    if (audioPlayer.src) {
      audioPlayer.play();
    } else {
      alert("No audio is loaded. Please select or record an audio file first.");
    }
  };

  const generateUniqueId = () => {
    const randomLetters = Math.random().toString(36).substring(2, 6); // Generate 4 random letters
    const timestamp = Date.now(); // Get current timestamp
    return `${randomLetters}-${timestamp}`;
  };

  const [selectedDeck, setSelectedDeck] = useState("");
  const [deckData, setDeckData] = useState({});

  useEffect(() => {
    const fetchDeckData = async () => {
      if (selectedDeck) {
        try {
          const deckModule = await import(
            `../Languages/${selectedDeck}/${selectedDeck}.json`
          );
          setDeckData(deckModule.default);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchDeckData();
  }, [selectedDeck]);

  const handleDeckChange = (event) => {
    setSelectedDeck(event.target.value);
  };

  const handleCardClick2 = (cardKey) => {
    const cardData = deckData[cardKey];
    if (cardData) {
      Swal.fire({
        html: `
          <section class="decksectionpopup">
            <form id="cardForm">
              <h1 class="deckinfotitle">${cardData.word}</h1>
              <h4 class="deckinfodescription">${cardData.meaning}</h4>
              <br>
              <style>
                #newCardsLabel, #setnewcards {
                  display: inline-block;
                }
              </style>
              <button class="signupbutton" id="cancelbutton" type="submit">Close</button>
            </form>
          </section>
        `,
        showConfirmButton: false,
        allowOutsideClick: true,
        customClass: {
          popup: "deckbodypopup",
          content: "decksectionpopup",
        },
        didOpen: () => {
          document
            .getElementById("cancelbutton")
            .addEventListener("click", (event) => {
              event.preventDefault();
              Swal.close();
            });
        },
      });
    }
  };

  return (
    <>
      <div id="deckview" className="deckview">
        <div className="grid-container">
          {deckNames.map((item, index) => {
            const percentage = ((item.cards / item.words) * 100).toFixed(2);
            let treeStage;

            if (
              percentage >= 0 &&
              percentage < 25 &&
              !item.tag.startsWith("m-")
            ) {
              treeStage = 0;
            } else if (
              percentage >= 25 &&
              percentage < 50 &&
              !item.tag.startsWith("m-")
            ) {
              treeStage = 1;
            } else if (
              percentage >= 50 &&
              percentage < 75 &&
              !item.tag.startsWith("m-")
            ) {
              treeStage = 2;
            } else if (
              percentage >= 75 &&
              percentage < 100 &&
              !item.tag.startsWith("m-")
            ) {
              treeStage = 3;
            } else if (percentage >= 100 || item.tag.startsWith("m-")) {
              treeStage = 4;
            }

            let deckKey = "";
            for (let key in langlist) {
              if (langlist[key].name === item.name) {
                deckKey = key;
                break;
              }
            }

            return (
              <>
                <div className="learnblinder" />
                <div
                  key={index}
                  className="card"
                  style={{
                    backgroundImage: `url(${item.background})`,
                  }}
                  onClick={() =>
                    handleCardClick(
                      item,
                      treeStage,
                      deckKey,
                      user,
                      setDeckNames
                    )
                  }
                >
                  <div className="top-left-container">
                    <img className="card-flag" src={item.flag} />
                    <div className="card-name">{item.name}</div>
                  </div>
                  <div className="card-total">
                    {item.tag.startsWith("m-")
                      ? item.cards + " cards in deck"
                      : `${percentage}% complete (${item.cards}/${item.words})`}
                  </div>
                  <div className="card-stars">
                    {item.points} stars collected
                  </div>
                  <div className="card-new">
                    {item.newCards} ready to learn today
                  </div>
                  <div className="card-review">
                    {item.reviewCards.length - item.newCards} left to review
                  </div>
                </div>
              </>
            );
          })}
          <div
            className="cardblank"
            onClick={() => pickerPopup(langlist, user, setDeckNames)}
          >
            <IoAddCircle />
          </div>

          <p style={{ color: "#55575B" }}>
            <br></br>
            New to Sudoto?{" "}
            <a
              href="https://youtu.be/hdesSe-J--A"
              target="_blank"
              style={{ color: "#55575B", textDecoration: "underline" }}
            >
              Watch this video
            </a>
            .
          </p>
          <p style={{ color: "#55575B" }}>
            <br></br>
            Found a bug?{" "}
            <a
              href="https://forms.gle/MEspjyYUNzuzsenR8"
              target="_blank"
              style={{ color: "#55575B", textDecoration: "underline" }}
            >
              Submit it here
            </a>
            .
          </p>
        </div>
      </div>
      <div id="practiceview" className="practiceview">
        <div className="grid-container">
          <div
            className="card"
            id="beginnerpractice"
            onClick={beginnerAiPopup}
            style={{
              background:
                "url(https://github.com/sudotolanguages/sudoto-public/blob/main/AIBackgrounds/ai1_1.png?raw=true) no-repeat center center/cover",
            }}
          >
            <div className="card-name">Beginner AI</div>
            <div className="card-total">
              Basic and easy sentences for practice
            </div>
          </div>
          <div
            className="card"
            id="intermediatepractice"
            onClick={intermediateAiPopup}
            style={{
              background:
                "url(https://github.com/sudotolanguages/sudoto-public/blob/main/AIBackgrounds/ai2_1.png?raw=true) no-repeat center center/cover",
            }}
          >
            <div className="card-name">Intermediate AI</div>
            <div className="card-new">Medium difficulty and more detailed</div>
          </div>
          <div
            className="card"
            id="expertpractice"
            onClick={expertAiPopup}
            style={{
              background:
                "url(https://github.com/sudotolanguages/sudoto-public/blob/main/AIBackgrounds/ai3_1.png?raw=true) no-repeat center center/cover",
            }}
          >
            <div className="card-name">Advanced AI</div>
            <div className="card-review">
              Difficult grammar and complex examples
            </div>
          </div>
        </div>
      </div>
      <div
        id="libraryview"
        className="libraryview flex flex-col md:flex-row justify-center px-4 sm:px-0 mt-20"
      >
        <div className="section1 flex-grow p-4 max-w-sm mx-auto bg-[#0E0F11] text-white rounded-lg shadow-md">
          <div className="space-y-4">
            {/* word  */}
            <div>
              <label
                htmlFor="word"
                className="block text-sm font-medium text-white"
              >
                Front (Required)
              </label>
              <input
                id="libword"
                name="word"
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-900 text-white pl-2"
              />
            </div>
            {/* meaning  */}
            <div>
              <label
                htmlFor="meaning"
                className="block text-sm font-medium text-white"
              >
                Back (Required)
              </label>
              <input
                id="libmeaning"
                name="meaning"
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-900 text-white pl-2"
              />
            </div>
            {/* sentence  */}
            <div>
              <label
                htmlFor="example"
                className="block text-sm font-medium text-white"
              >
                Example Sentence
              </label>
              <input
                id="examplesentence"
                name="examplesentence"
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-900 text-white pl-2"
              />
            </div>
            {/* sentence meaning  */}
            <div>
              <label
                htmlFor="example"
                className="block text-sm font-medium text-white"
              >
                Sentence Meaning
              </label>
              <input
                id="examplemeaning"
                name="examplemeaning"
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-900 text-white pl-2"
              />
            </div>
            {/* just need to take these out for now */}
            {/* audio  
            <div>
              <label
                htmlFor="audio"
                className="block text-sm font-medium text-white"
              >
                Word Audio
              </label>
              <div className="flex items-center space-x-2">
                <input
                  id="audio"
                  name="audio"
                  type="file"
                  accept=".ogg,.wav,.mp3"
                  className="mt-1 block w-full rounded-md bg-gray-900 text-white"
                  onChange={changePlayback}
                />
                <button
                  onClick={() => handleAudioRecord("audio", "record-button")}
                  className="mt-1 px-4 py-2 rounded-md bg-gray-900 hover:bg-gray-800 transition duration-200 text-white record-button"
                >
                  <FaMicrophone />
                </button>
                <button
                  onClick={() => handlePlayAudio("audio")}
                  className="mt-1 px-4 py-2 rounded-md bg-gray-900 hover:bg-gray-800 transition duration-200 text-white play-button"
                >
                  <IoMdPlayCircle />
                </button>
              </div>
            </div>
            <div id="audio-container">
              <audio
                id="audio-player"
                controls
                style={{ display: "none" }}
              ></audio>
            </div>*/}
            {/* sentence audio  
            <div>
              <label
                htmlFor="audio"
                className="block text-sm font-medium text-white"
              >
                Sentence Audio
              </label>
              <div className="flex items-center space-x-2">
                <input
                  id="sentenceaudio"
                  name="audio"
                  type="file"
                  accept=".ogg,.wav,.mp3"
                  className="mt-1 block w-full rounded-md bg-gray-900 text-white"
                  onChange={changePlayback}
                />
                <button
                  onClick={() =>
                    handleAudioRecord("sentenceaudio", "sentence-record-button")
                  }
                  className="mt-1 px-4 py-2 rounded-md bg-gray-900 hover:bg-gray-800 transition duration-200 text-white sentence-record-button"
                >
                  <FaMicrophone />
                </button>
                <button
                  onClick={() => handlePlayAudio("sentenceaudio")}
                  className="mt-1 px-4 py-2 rounded-md bg-gray-900 text-white hover:bg-gray-800 transition duration-200 play-button"
                >
                  <IoMdPlayCircle />
                </button>
              </div>
            </div>
            <div id="sentenceaudio-container">
              <audio
                id="sentenceaudio-player"
                controls
                style={{ display: "none" }}
              ></audio>
            </div>
            <ImageUpload onImageUpload={handleImageUpload} />*/}
            {/*
            <button className="mt-4 px-4 py-2 w-full rounded-md bg-gray-900 hover:bg-gray-800 transition duration-200 text-white">
              Save Card
                </button>*/}

            <div className="flex justify-between mt-4">
              {/* Button on the far left side */}
              <button
                onClick={() => {
                  Swal.fire({
                    html: `
        <section class="decksectionpopup">
          <form id="signupForm">
            <h1 class="deckinfotitle">Create New Deck</h1>
            <div class="inputbox">
              <input type="text" required id="deckcode" />
              <label for="">4 letter deck code</label>
            </div>
            <br>
            <style>
              #newCardsLabel, #setnewcards {
                display: inline-block;
              }
            </style>
            <button class="signupbutton" id="cancelbutton" type="submit">Save</button>
          </form>
        </section>
      `,
                    showConfirmButton: false,
                    allowOutsideClick: true,
                    customClass: {
                      popup: "deckbodypopup",
                      content: "decksectionpopup",
                    },
                    didOpen: () => {
                      document
                        .getElementById("cancelbutton")
                        .addEventListener("click", (event) => {
                          event.preventDefault();

                          const deckcode =
                            document.getElementById("deckcode").value;
                          const isValid = /^[a-zA-Z0-9]{4}$/.test(deckcode);

                          if (isValid) {
                            const newString = "m-" + deckcode;
                            const data = {
                              newString: newString,
                              uid: user.uid,
                            };

                            fetch(`${apiUrl}/rdb/makedeck`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify(data),
                            })
                              .then((response) => response.json())
                              .then((data) => {
                                if (data.success) {
                                  Swal.close();
                                  window.location.reload();
                                  GetDecks(user, setDeckNames);
                                } else {
                                  Swal.showValidationMessage(
                                    "Failed to create deck!"
                                  );
                                  return;
                                }
                                console.log("Deck removed successfully");

                                Swal.close();
                                window.location.reload();
                                GetDecks(user, setDeckNames);
                              })
                              .catch((error) => {
                                console.error("Error:", error);
                              });
                            GetDecks(user, setDeckNames);
                            Swal.close();
                          } else {
                            Swal.showValidationMessage(
                              "Deck code must be 4 characters!"
                            );
                          }
                        });
                    },
                  });
                }}
                className="h-10 mt-1 px-4 py-2 rounded-md bg-gray-900 text-white hover:bg-gray-800 transition duration-200 play-button"
              >
                <IoMdAddCircle />
              </button>

              {/* Dropdown in the middle */}
              <select
                id="deckDropdown"
                className="h-10 w-1/2 mr-2 ml-2 mt-1 bg-gray-900 text-white rounded-md pl-2"
              >
                {Object.keys(customCards).length > 0 ? (
                  Object.keys(customCards).map((key) => (
                    <option key={key} value={key}>
                      {key.substring(2)}
                    </option>
                  ))
                ) : (
                  <option value="">Select a deck</option>
                )}
              </select>

              <button
                className="h-10 mt-1 px-4 py-2 rounded-md bg-gray-900 text-white hover:bg-gray-800 transition duration-200 play-button"
                onClick={() => {
                  const word = document.getElementById("libword").value;
                  const meaning = document.getElementById("libmeaning").value;
                  let data = {};
                  const deckKey = document.getElementById("deckDropdown").value;

                  if (!word || !meaning || deckKey === "") {
                    Swal.fire({
                      html: `
          <section class="decksectionpopup">
          <form id="signupForm">
            <h1 class="deckinfotitle">Oops...</h1>
            
            <h4 class="deckinfodescription">You need a word, meaning, and deck to create a card.</h4>
            <br>
            <style>
              #newCardsLabel, #setnewcards {
                display: inline-block;
              }
            </style>
          
            <button class="signupbutton" id="cancelbutton" type="submit">Close</button>
          </form>
          </section>
          `,
                      showConfirmButton: false,
                      allowOutsideClick: true,
                      customClass: {
                        popup: "deckbodypopup",
                        content: "decksectionpopup",
                      },
                      didOpen: () => {
                        document
                          .getElementById("cancelbutton")
                          .addEventListener("click", (event) => {
                            event.preventDefault();
                            Swal.close();
                          });
                      },
                    });
                    return;
                  }

                  const cardId = generateUniqueId();

                  data = {
                    word: word,
                    meaning: meaning,
                    exampleSentence:
                      document.getElementById("examplesentence").value,
                    exampleMeaning:
                      document.getElementById("examplemeaning").value,
                    audio: null,
                    sentenceAudio: null,
                    imageSrc: null,
                    deckKey: deckKey,
                    cardId: cardId,
                    uid: user.uid,
                    interval: 0,
                    lastReviewed: new Date().toISOString(),
                    dateLearned: new Date().toISOString(),
                    isSuspended: false,
                  };

                  fetch(`${apiUrl}/rdb/makecard`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      if (data.status === "success") {
                        console.log("this ran 1");
                        GetDecks(user, setDeckNames);
                        document.getElementById("libword").value = "";
                        document.getElementById("libmeaning").value = "";
                        document.getElementById("examplesentence").value = "";
                        document.getElementById("examplemeaning").value = "";
                      } else {
                        Swal.fire({
                          html: `
              <section class="decksectionpopup">
              <form id="signupForm">
                <h1 class="deckinfotitle">Oops...</h1>
                
                <h4 class="deckinfodescription">Failed to create card...</h4>
                <br>
                <style>
                  #newCardsLabel, #setnewcards {
                    display: inline-block;
                  }
                </style>
              
                <button class="signupbutton" id="cancelbutton" type="submit">Close</button>
              </form>
              </section>
              `,
                          showConfirmButton: false,
                          allowOutsideClick: true,
                          customClass: {
                            popup: "deckbodypopup",
                            content: "decksectionpopup",
                          },
                          didOpen: () => {
                            document
                              .getElementById("cancelbutton")
                              .addEventListener("click", (event) => {
                                event.preventDefault();
                                Swal.close();
                              });
                          },
                        });
                      }
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                }}
              >
                Save
              </button>

              {/* Text input on the right side 
              <input
                type="text"
                className="w-1/2 ml-2 bg-gray-900 text-white rounded-md"
                placeholder=" Card ID"
              />*/}
            </div>
          </div>
        </div>
        <br></br>
        <div className="section1 flex-grow p-4 max-w-4xl mx-auto bg-[#0E0F11] text-white rounded-lg shadow-md">
          <div className="flex justify-center mb-4">
            <select
              id="deckDropdown2"
              className="h-10 w-1/2 bg-gray-900 text-white rounded-md pl-2"
              value={selectedDeck}
              onChange={handleDeckChange}
            >
              <option value="">Select a deck</option>
              {Object.keys(rawCardData).map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {selectedDeck &&
              Object.keys(rawCardData[selectedDeck])
                .filter((key) => key !== "newCards" && key !== "points")
                .map((cardKey) => (
                  <div
                    key={cardKey}
                    className="bg-gray-800 p-4 rounded-lg shadow-md flex justify-between items-center cursor-pointer"
                    onClick={() => handleCardClick2(cardKey)}
                  >
                    <h2 className="text-xl font-semibold flex-1 mr-2 overflow-hidden whitespace-normal break-all">
                      {deckData[cardKey] ? deckData[cardKey].word : cardKey}
                    </h2>
                  </div>
                ))}
          </div>
        </div>

        <br></br>
      </div>
      <div id="watchview" className="watchview">
        <Watch />
      </div>
      <div
        id="settingsview"
        className="settingsview w-screen flex justify-center px-4 sm:px-0 mt-20"
      >
        <div className="p-4 max-w-sm mx-auto bg-[#0E0F11] text-white rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <div className="relative h-16 w-16">
                <input
                  type="file"
                  id="imageInput"
                  style={{ display: "none" }}
                />

                <button
                  id="pfpbutton"
                  className="absolute inset-0 focus:outline-none"
                  onClick={() => {
                    const isAvailable = false;
                    if (!isAvailable) {
                      return;
                    }
                    const input = document.getElementById("imageInput");
                    input.click();

                    input.onchange = () => {
                      const formData = new FormData();
                      formData.append("image", input.files[0]);

                      // Get the uid from the user object
                      formData.append("uid", user.uid);

                      fetch(`${apiUrl}/rdb/upload-image`, {
                        method: "POST",
                        body: formData,
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          if (data.success) {
                            console.log("Image uploaded successfully");
                            fetch(`${apiUrl}/rdb/get-image/${user.uid}`)
                              .then((response) => response.text())
                              .then((base64Image) => {
                                // Set the src of the image element to the base64 string
                                document.getElementById("userpfp").src =
                                  "data:image/jpeg;base64," + base64Image;
                              })
                              .catch((error) => console.error("Error:", error));
                          } else {
                            console.error("Error uploading image:", data.error);
                            Swal.fire({
                              html: `
<section class="signupsectionpopup">
<form id="signupForm">
<h1 class="text-lg text-white">Failed to upload image. Please try again. Use either a .jpg, a .jpeg, or a .png file under 2MB in size.</h1>
<br>

<button id="but" class="signupbutton" type="button">Close</button>
</form>
</section>
`,
                              showConfirmButton: false,
                              allowOutsideClick: true,
                              customClass: {
                                popup: "signupbodypopup",
                                content: "signupsectionpopup",
                              },
                              didOpen: () => {
                                const button = document.getElementById("but");
                                button.addEventListener("click", (event) => {
                                  event.preventDefault();
                                  Swal.close();
                                });
                              },
                            });
                          }
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                        });
                    };
                  }}
                >
                  <img
                    className="h-full w-full rounded-full transition-all duration-500 ease-in-out hover:opacity-50"
                    src="https://placehold.co/64x64"
                    alt="Profile Picture"
                    id="userpfp"
                  />
                  <div className="absolute inset-0 flex items-center justify-center transition-all duration-500 ease-in-out opacity-0 hover:opacity-0">
                    <MdUpload size={30} color="black" />
                  </div>
                </button>
              </div>
              <div className="ml-3">
                <h5 id="displaynamefield" className="text-xl font-semibold">
                  Name
                </h5>
                <p id="usernamefield" className="text-sm text-white">
                  @username
                </p>
                <p id="joinedusername" className="text-sm text-white">
                  Joined
                </p>
              </div>
            </div>
            <button
              id="editprofile"
              className="text-white hover:text-gray-400 transition-colors duration-300 ease-in-out focus:outline-none"
            ></button>
          </div>
          <div className="flex justify-start text-center">
            <p id="langgoalbox" className="text-lg font-semibold">
              Language goal
            </p>
          </div>
          <div className="flex justify-between mt-4 space-x-4">
            <button
              className="bg-[#23262B] hover:bg-[#1A1C1F] transition-colors duration-300 ease-in-out font-medium text-sm py-1 px-2 rounded-lg"
              onClick={() => {
                const email = auth.currentUser?.email; // replace with the user's email
                sendPasswordResetEmail(auth, email)
                  .then(() => {
                    Swal.fire({
                      html: `
<section class="signupsectionpopup">
<form id="signupForm">
<h1 class="text-lg text-white">Password reset email sent!</h1>
<br>

<button id="but" class="signupbutton" type="button">Close</button>
</form>
</section>
`,
                      showConfirmButton: false,
                      allowOutsideClick: true,
                      customClass: {
                        popup: "signupbodypopup",
                        content: "signupsectionpopup",
                      },
                      didOpen: () => {
                        const button = document.getElementById("but");
                        button.addEventListener("click", (event) => {
                          event.preventDefault();
                          Swal.close();
                        });
                      },
                    });
                  })
                  .catch((error) => {
                    Swal.fire("Error", error.message, "error");
                  });
              }}
            >
              Password Reset
            </button>
            <button
              className="bg-[rgb(65,54,54)] hover:bg-[rgb(45,37,37)] transition-colors duration-300 ease-in-out font-medium text-sm py-1 px-2 rounded-lg"
              onClick={() => {
                signOut(auth)
                  .then(() => {
                    // Sign-out successful.
                    window.location.href = "/";
                  })
                  .catch((error) => {
                    // An error happened.
                    console.error(error);
                  });
              }}
            >
              Logout
            </button>
          </div>
        </div>
        <br></br>
      </div>
    </>
  );
};

let reviewCards = {};
let customCards = {};
let streakData = [];
let calendarData = [];
let aiCards = [];
let streak = 0;
let streak1 = 0;
let totalPoints = 0;
let rawCardData = {};

async function GetDecks(user, setDeckNames) {
  let elements = document.getElementsByClassName("learnblinder");
  totalPoints = 0;
  streakData = [];
  calendarData = [];

  streak = 0;
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = "none";
  }
  console.log("Getting decks...");
  const response = await fetch(`${apiUrl}/rdb/get-decks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ uid: user.uid }),
  });
  const text = await response.text();
  rawCardData = JSON.parse(text);
  console.log(text);
  const data = JSON.parse(text);
  const names = [];
  const today = new Date();
  for (let key in data) {
    if (langlist[key]) {
      console.log(langlist[key].name);

      // Get the number of cards in the deck
      const cardsCount = Object.keys(data[key]).length;

      /*streak = 0;
      totalPoints = 0;
      calendarData = [];
      streakData = [];*/

      // Get the newCards value
      let newCards = data[key].newCards;
      let newCardsNumber = data[key].newCards;
      let points = data[key].points;
      let isAi = langlist[key].ai;
      totalPoints += points;

      console.log("wieghwie " + newCards);

      // Initialize the reviewCards array for this deck
      reviewCards[langlist[key].name] = [];

      let newLearnCards = {};

      let learnedToday = 0;

      //console.log("New Learn Cards: ", newLearnCards[key]);

      // Subtract 1 from newCards for every card that was learned today
      // Assume `data` is your main object structure as before
      for (let cardKey in data[key]) {
        if (cardKey !== "newCards" && cardKey !== "points") {
          // Normalize today's date to start of the day
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          // Get the learned date and normalize it
          const dateLearnedUTC = data[key][cardKey].dateLearned;
          const dateLearnedLocal = dateLearnedUTC
            ? new Date(dateLearnedUTC)
            : null;
          if (dateLearnedLocal) {
            dateLearnedLocal.setHours(0, 0, 0, 0); // Normalize learned date
          }

          // Check if the learned date is today and the card is not suspended
          if (
            dateLearnedLocal &&
            +dateLearnedLocal === +today &&
            data[key][cardKey].isSuspended === false
          ) {
            learnedToday++;
            newCards--;
            const firstWord = langlist[key].name.split(" ")[0];
            // Dynamic import based on the language list
            const deckModule = await import(
              `../Languages/${langlist[key].code}/${langlist[key].code}.json`
            );
            let deck = deckModule.default;
            let word = deck[cardKey].word;
            word = word.replace(/\(.*?\)/g, "");

            if (
              !aiCards.includes(word + " for " + firstWord) &&
              isAi === true
            ) {
              aiCards.push(word + " for " + firstWord);
            }
          }

          // Streak and calendar data logic...
          if (dateLearnedLocal) {
            streakData.push(dateLearnedLocal);
            calendarData.push(dateLearnedLocal.toLocaleDateString("en-CA"));
          }

          // Check if the card qualifies for review
          const lastReviewed = new Date(data[key][cardKey].lastReviewed);
          const interval = data[key][cardKey].interval;
          const isSuspended = data[key][cardKey].isSuspended;
          let reviewDate;
          if (isSuspended) {
            continue;
          }
          switch (interval) {
            case 0:
              if (reviewCards[langlist[key].name].length > 2) {
                const index =
                  Math.floor(
                    Math.random() * (reviewCards[langlist[key].name].length - 1)
                  ) + 1;
                reviewCards[langlist[key].name].splice(index, 0, cardKey);
              } else {
                reviewCards[langlist[key].name].push(cardKey);
              }
              break;
            case 1:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 1)
              );
              break;
            case 2:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 2)
              );
              break;
            case 3:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 4)
              );
              break;
            case 4:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 7)
              );
              break;
            case 5:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 14)
              );
              break;
            case 6:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 28)
              );
              break;
            case 7:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 60)
              );
              break;
            case 8:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 90)
              );
              break;
          }

          if (reviewDate && reviewDate <= today) {
            if (reviewCards[langlist[key].name].length > 2) {
              const index =
                Math.floor(
                  Math.random() * (reviewCards[langlist[key].name].length - 1)
                ) + 1;
              reviewCards[langlist[key].name].splice(index, 0, cardKey);
            } else {
              reviewCards[langlist[key].name].push(cardKey);
            }
          }
        }
      }

      let highestCardNumber = Math.max(
        ...Object.keys(data[key]).filter(
          (cardKey) => cardKey !== "newCards" && !isNaN(cardKey)
        )
      );
      // If there are no cards in the deck, start from 0
      if (highestCardNumber === -Infinity) {
        highestCardNumber = 0;
      }

      // If cardsCount + newCards is greater than or equal to langlist[key].words, set newCards to langlist[key].words - cardsCount
      if (Number(cardsCount) + Number(newCards) >= langlist[key].words) {
        newCards = langlist[key].words - cardsCount + 2;
      }

      // Add newCards cards to the deck, starting from highestCardNumber + 1
      newLearnCards[key] = Array.from({ length: newCards }, (_, i) =>
        (highestCardNumber + i + 1).toString().padStart(4, "0")
      );

      // ...

      streakData.sort((a, b) => b - a); // Sort in descending order

      let todayDate = new Date();
      todayDate.setMinutes(
        todayDate.getMinutes() - todayDate.getTimezoneOffset()
      );
      let todayDateString = todayDate.toISOString().split("T")[0];

      if (
        streakData.length > 0 &&
        streakData[0].toISOString().split("T")[0] === todayDateString
      ) {
        streak++;
        for (let i = 1; i < streakData.length; i++) {
          let previousDay = new Date(streakData[i - 1]);
          previousDay.setMinutes(
            previousDay.getMinutes() - previousDay.getTimezoneOffset()
          );
          previousDay.setDate(previousDay.getDate() - 1);
          if (
            streakData[i].toISOString().split("T")[0] ===
            previousDay.toISOString().split("T")[0]
          ) {
            streak++;
          } else {
            break;
          }
        }
      }

      console.log("Streak: ", streak);

      document.getElementById("streak").textContent = streak;
      document.getElementById("points").textContent = totalPoints;
      setStreakData();

      reviewCards[langlist[key].name].push(...newLearnCards[key]);

      console.log("Review Cards: ", reviewCards);
      console.log("New Cards: ", newCards);

      names.unshift({
        name: langlist[key].name,
        tag: key,
        background: langlist[key].background,
        description: langlist[key].description,
        flag: langlist[key].flag,
        words: langlist[key].words, // assuming this is the property name
        cards: cardsCount - 2, // add the number of cards to the deck data
        newCards: newCards, // add the newCards value to the deck data
        newCardsNumber: newCardsNumber,
        reviewCards: reviewCards[langlist[key].name], // add the reviewCards array for this deck to the deck data
        newLearnCards: newLearnCards[key],
        points: points,
        text: text,
        explainButton: langlist[key].explainButton,
        detailedEvolution: langlist[key].detailedEvolution,
        furigana: langlist[key].furigana,
        content: langlist[key].content,
      });
    } else {
      // must be a custom deck

      // REMEMBER its gonna calculate the new cards wrong because it goes by number, just go by last from the list

      console.log(key);

      // Get the number of cards in the deck
      const cardsCount = Object.keys(data[key]).length;

      /*streak = 0;
      totalPoints = 0;
      calendarData = [];
      streakData = [];*/

      // Get the newCards value
      let newCards = data[key].newCards;
      let newCardsNumber = data[key].newCards;
      let points = data[key].points;
      // should custom cards use ai?
      let isAi = false;
      totalPoints += points;

      console.log("wieghwie " + newCards);

      // Initialize the reviewCards array for this deck
      customCards[key] = [];

      let newLearnCards = {};

      let learnedToday = 0;

      //console.log("New Learn Cards: ", newLearnCards[key]);

      // Subtract 1 from newCards for every card that was learned today
      // Assume `data` is your main object structure as before
      for (let cardKey in data[key]) {
        if (cardKey !== "newCards" && cardKey !== "points") {
          // Normalize today's date to start of the day
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          // Get the learned date and normalize it
          const dateLearnedUTC = data[key][cardKey].dateLearned;
          const dateLearnedLocal = dateLearnedUTC
            ? new Date(dateLearnedUTC)
            : null;
          if (dateLearnedLocal) {
            dateLearnedLocal.setHours(0, 0, 0, 0); // Normalize learned date
          }

          // Check if the learned date is today and the card is not suspended
          if (
            dateLearnedLocal &&
            +dateLearnedLocal === +today &&
            data[key][cardKey].isSuspended === false
          ) {
            learnedToday++;
            newCards--;
            // basically all of this stuff is ai
            //
            //

            //const firstWord = langlist[key].name.split(" ")[0];
            // Dynamic import based on the language list

            // IT HAS TO GET THE LIBRARIES DECK AS THE JSON DATA
            //
            /*
            const deckModule = await import(
              `../Languages/${langlist[key].code}/${langlist[key].code}.json`
            );
            let deck = deckModule.default;
            let word = deck[cardKey].word;
            word = word.replace(/\(.*?\)/g, "");*/

            // ai card push logic
            /*if (
              !aiCards.includes(word + " for " + firstWord) &&
              isAi === true
            ) {
              aiCards.push(word + " for " + firstWord);
            }*/
          }

          // Streak and calendar data logic...
          if (dateLearnedLocal) {
            streakData.push(dateLearnedLocal);
            calendarData.push(dateLearnedLocal.toLocaleDateString("en-CA"));
          }

          // Check if the card qualifies for review
          const lastReviewed = new Date(data[key][cardKey].lastReviewed);
          const interval = data[key][cardKey].interval;
          const isSuspended = data[key][cardKey].isSuspended;
          let reviewDate;
          if (isSuspended) {
            continue;
          }
          switch (interval) {
            case 0:
              if (customCards[key].length > 2) {
                const index =
                  Math.floor(Math.random() * (customCards[key].length - 1)) + 1;
                customCards[key].splice(index, 0, cardKey);
              } else {
                customCards[key].push(cardKey);
              }
              break;
            case 1:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 1)
              );
              break;
            case 2:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 2)
              );
              break;
            case 3:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 4)
              );
              break;
            case 4:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 7)
              );
              break;
            case 5:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 14)
              );
              break;
            case 6:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 28)
              );
              break;
            case 7:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 60)
              );
              break;
            case 8:
              reviewDate = new Date(
                lastReviewed.setDate(lastReviewed.getDate() + 90)
              );
              break;
          }

          if (reviewDate && reviewDate <= today) {
            if (customCards[key].length > 2) {
              const index =
                Math.floor(Math.random() * (customCards[key].length - 1)) + 1;
              customCards[key].splice(index, 0, cardKey);
            } else {
              customCards[key].push(cardKey);
            }
          }
        }
      }

      let highestCardNumber = Math.max(
        ...Object.keys(data[key]).filter(
          (cardKey) => cardKey !== "newCards" && !isNaN(cardKey)
        )
      );
      // If there are no cards in the deck, start from 0
      if (highestCardNumber === -Infinity) {
        highestCardNumber = 0;
      }

      // If cardsCount + newCards is greater than or equal to langlist[key].words, set newCards to langlist[key].words - cardsCount

      // THIS has to be the amount of cards TOTAL in the library
      //
      //
      /*if (Number(cardsCount) + Number(newCards) >= langlist[key].words) {
        newCards = langlist[key].words - cardsCount + 2;
      }*/
      // THIS MIGHT COMPLETELY WRONG LMAO
      if (Number(cardsCount) + Number(newCards) >= cardsCount + 2) {
        newCards = cardsCount - cardsCount;
      }
      //
      //

      // Add newCards cards to the deck, starting from highestCardNumber + 1
      newLearnCards[key] = Array.from({ length: newCards }, (_, i) =>
        (highestCardNumber + i + 1).toString().padStart(4, "0")
      );

      // ...

      streakData.sort((a, b) => b - a); // Sort in descending order

      let todayDate = new Date();
      todayDate.setMinutes(
        todayDate.getMinutes() - todayDate.getTimezoneOffset()
      );
      let todayDateString = todayDate.toISOString().split("T")[0];

      if (
        streakData.length > 0 &&
        streakData[0].toISOString().split("T")[0] === todayDateString
      ) {
        streak++;
        for (let i = 1; i < streakData.length; i++) {
          let previousDay = new Date(streakData[i - 1]);
          previousDay.setMinutes(
            previousDay.getMinutes() - previousDay.getTimezoneOffset()
          );
          previousDay.setDate(previousDay.getDate() - 1);
          if (
            streakData[i].toISOString().split("T")[0] ===
            previousDay.toISOString().split("T")[0]
          ) {
            streak++;
          } else {
            break;
          }
        }
      }

      console.log("Streak: ", streak);

      document.getElementById("streak").textContent = streak;
      document.getElementById("points").textContent = totalPoints;
      setStreakData();

      customCards[key].push(...newLearnCards[key]);

      console.log("Review Cards: ", reviewCards);
      console.log("New Cards: ", newCards);

      names.unshift({
        name: key.substring(2),
        tag: key,
        background:
          "https://github.com/sudotolanguages/sudoto-public/blob/main/LangBackgroundsShaded/ink.png?raw=true",
        description:
          "A custom deck that you made. Add cards to it in the library menu.",
        flag: "https://github.com/sudotolanguages/sudoto-public/blob/main/LangFlags/star.png?raw=true",
        words: customCards[key], // if its 1 you KNOW its a custom deck lmao
        cards: cardsCount - 2,
        newCards: newCards,
        newCardsNumber: newCardsNumber,
        reviewCards: customCards[key],
        newLearnCards: newLearnCards[key],
        points: points,
        text: text,
        explainButton: true, // MAKE THIS DYNAMIC ON THE SWAL
        detailedEvolution: true, // MAKE THIS CHANGEABLE
        furigana: true,
        content: null,
      });
    }
  }
  setDeckNames(names);

  /*const db = getDatabase();
  const decksRef = ref(db, `users/${user.uid}/decks`);
  get(decksRef).then(
    async (snapshot) => {
      const data = snapshot.val();
      const names = [];
      const today = new Date();
      for (let key in data) {
        if (langlist[key]) {
          console.log(langlist[key].name);

          // Get the number of cards in the deck
          const cardsCount = Object.keys(data[key]).length;

          streak = 0;
          totalPoints = 0;
          calendarData = [];
          streakData = [];

          // Get the newCards value
          let newCards = data[key].newCards;
          let newCardsNumber = data[key].newCards;
          let points = data[key].points;
          totalPoints += points;

          // Initialize the reviewCards array for this deck
          reviewCards[langlist[key].name] = [];

          let newLearnCards = {};

          let learnedToday = 0;

          //console.log("New Learn Cards: ", newLearnCards[key]);

          // Subtract 1 from newCards for every card that was learned today
          // Assume `data` is your main object structure as before
          for (let cardKey in data[key]) {
            if (cardKey !== "newCards" && cardKey !== "points") {
              // Get today's date and normalize it to the start of the day in local time
              const today = new Date();
              today.setHours(0, 0, 0, 0); // Normalize today's date to start of the day

              // Get the learned date in local time and normalize it
              const dateLearnedUTC = data[key][cardKey].dateLearned;
              const dateLearnedLocal = dateLearnedUTC
                ? new Date(dateLearnedUTC)
                : null;
              if (dateLearnedLocal) {
                dateLearnedLocal.setHours(0, 0, 0, 0); // Normalize learned date to start of the day
              }

              // Check if the learned date is today and not suspended
              if (
                dateLearnedLocal &&
                +dateLearnedLocal === +today &&
                data[key][cardKey].isSuspended === false
              ) {
                // Further logic for processing cards learned today
                if (newCards > 0) {
                  newCards--;
                }
                learnedToday++;
                const firstWord = langlist[key].name.split(" ")[0];
                // Dynamic import based on the language list
                const deckModule = await import(
                  `../Languages/${langlist[key].code}/${langlist[key].code}.json`
                );
                let deck = deckModule.default;
                let word = deck[cardKey].word;
                word = word.replace(/\(.*?\)/g, "");

                if (!aiCards.includes(word + " for " + firstWord)) {
                  aiCards.push(word + " for " + firstWord);
                }
              }

              // Streak and calendar data logic...
              if (dateLearnedLocal) {
                streakData.push(dateLearnedLocal);
                calendarData.push(dateLearnedLocal.toLocaleDateString("en-CA"));
              }

              // Check if the card qualifies for review
              const lastReviewed = new Date(data[key][cardKey].lastReviewed);
              const interval = data[key][cardKey].interval;
              const isSuspended = data[key][cardKey].isSuspended;
              let reviewDate;
              if (isSuspended) {
                continue;
              }
              switch (interval) {
                case 0:
                  reviewCards[langlist[key].name].push(cardKey);
                  break;
                case 1:
                  reviewDate = new Date(
                    lastReviewed.setDate(lastReviewed.getDate() + 1)
                  );
                  break;
                case 2:
                  reviewDate = new Date(
                    lastReviewed.setDate(lastReviewed.getDate() + 2)
                  );
                  break;
                case 3:
                  reviewDate = new Date(
                    lastReviewed.setDate(lastReviewed.getDate() + 4)
                  );
                  break;
                case 4:
                  reviewDate = new Date(
                    lastReviewed.setDate(lastReviewed.getDate() + 7)
                  );
                  break;
                case 5:
                  reviewDate = new Date(
                    lastReviewed.setDate(lastReviewed.getDate() + 14)
                  );
                  break;
                case 6:
                  reviewDate = new Date(
                    lastReviewed.setDate(lastReviewed.getDate() + 28)
                  );
                  break;
                case 7:
                  reviewDate = new Date(
                    lastReviewed.setDate(lastReviewed.getDate() + 60)
                  );
                  break;
                case 8:
                  reviewDate = new Date(
                    lastReviewed.setDate(lastReviewed.getDate() + 90)
                  );
                  break;
              }

              if (reviewDate && reviewDate <= today) {
                reviewCards[langlist[key].name].push(cardKey);
              }
            }
          }

          let highestCardNumber = Math.max(
            ...Object.keys(data[key]).filter(
              (cardKey) => cardKey !== "newCards" && !isNaN(cardKey)
            )
          );
          // If there are no cards in the deck, start from 0
          if (highestCardNumber === -Infinity) {
            highestCardNumber = 0;
          }

          // Subtract from newCards until cardsCount + newCards is less than or equal to langlist[key].words
          while (cardsCount + newCards > langlist[key].words + 1) {
            newCards--;
          }

          // Add newCards cards to the deck, starting from highestCardNumber + 1
          newLearnCards[key] = Array.from({ length: newCards }, (_, i) =>
            (highestCardNumber + i + 1).toString().padStart(4, "0")
          );

          // ...

          streakData.sort((a, b) => b - a); // Sort in descending order

          let todayDate = new Date();
          todayDate.setMinutes(
            todayDate.getMinutes() - todayDate.getTimezoneOffset()
          );
          let todayDateString = todayDate.toISOString().split("T")[0];

          if (
            streakData.length > 0 &&
            streakData[0].toISOString().split("T")[0] === todayDateString
          ) {
            streak++;
            for (let i = 1; i < streakData.length; i++) {
              let previousDay = new Date(streakData[i - 1]);
              previousDay.setMinutes(
                previousDay.getMinutes() - previousDay.getTimezoneOffset()
              );
              previousDay.setDate(previousDay.getDate() - 1);
              if (
                streakData[i].toISOString().split("T")[0] ===
                previousDay.toISOString().split("T")[0]
              ) {
                streak++;
              } else {
                break;
              }
            }
          }

          console.log("Streak: ", streak);

          document.getElementById("streak").textContent = streak;
          document.getElementById("points").textContent = totalPoints;
          setStreakData();

          reviewCards[langlist[key].name].push(...newLearnCards[key]);

          console.log("Review Cards: ", reviewCards);

          names.unshift({
            name: langlist[key].name,
            tag: key,
            background: langlist[key].background,
            description: langlist[key].description,
            flag: langlist[key].flag,
            words: langlist[key].words, // assuming this is the property name
            cards: cardsCount - 2, // add the number of cards to the deck data
            newCards: newCards, // add the newCards value to the deck data
            newCardsNumber: newCardsNumber,
            reviewCards: reviewCards[langlist[key].name], // add the reviewCards array for this deck to the deck data
            newLearnCards: newLearnCards[key],
            points: points,
          });
        }
      }
      setDeckNames(names);
    },
    (error) => {
      console.error("Error: ", error);
    }
  );*/
}

function handleCardClick(item, treeStage, deckKey, user, setDeckNames) {
  Swal.fire({
    html: `
<section class="decksectionpopup">
  <form id="signupForm">
    <h1 class="deckinfotitle">${item.name}</h1>
    <h4 class="deckinfodescription">${item.description}</h4>
    <br>
    <img id="dynamicImage" class="block mx-auto" style="width: 200px; height: 200px; border-radius: 16px;">
    <br>
    <h4 class="deckinfodescription">As you progress, the seed will keep growing into a tree.</h4>
    <br>
    <style>
      #newCardsLabel, #setnewcards {
        display: inline-block;
      }
    </style>

    <h4 class="deckinfodescription" id="newCardsLabel" for="newCards">New cards: ${item.newCards}</h4>
    <button class="adjustbutton" id="setnewcards" type="submit">Options</button>
    <br><br>
    
    <button class="signupbutton" id="learnButton" type="submit">Learn</button>
    
  </form>
</section>
`,
    showConfirmButton: false,
    allowOutsideClick: true,
    customClass: {
      popup: "deckbodypopup",
      content: "decksectionpopup",
    },

    didOpen: async () => {
      const popup = Swal.getPopup();

      /*fetch(`${apiUrl}/rdb/setjson`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => {
          console.error("Error:", error);
        });*/

      const adjustbutton = popup.querySelector("#setnewcards");
      const dynamicImage = popup.querySelector("#dynamicImage");

      const imageModule = await import(`../Assets/trees/${treeStage}.png`);
      dynamicImage.src = imageModule.default;

      adjustbutton.addEventListener("click", (event) => {
        /* swal for adjusting cards */
        Swal.fire({
          html: `
<section class="decksectionpopup">
  <form id="signupForm">
    <h1 class="deckinfotitle">Set daily cards</h1>
    
    <h4 class="deckinfodescription">We recommend at least 10 cards a day.</h4>
    <br>
    <style>
      #newCardsLabel, #setnewcards {
        display: inline-block;
      }
    </style>

    <input type="number" id="newCardsInput" name="newCardsInput" min="0" max="100" value="${item.newCardsNumber}" style="width: 120px; text-align: center; border-radius: 50px; border: none;">
    <br><br><br>
    
    <button class="signupbutton" id="savebutton">Save</button>
    <br><br>
    <button class="flipbutton" id="deletedeck" type="submit">Delete Deck</button>
    
    
  </form>
</section>
`,
          showConfirmButton: false,
          allowOutsideClick: true,
          customClass: {
            popup: "deckbodypopup",
            content: "decksectionpopup",
          },

          didOpen: () => {
            const savebutton = document.querySelector("#savebutton");
            const deleteDeckButton = document.querySelector("#deletedeck");

            savebutton.addEventListener("click", (event) => {
              event.preventDefault();
              const database = getDatabase();
              const auth = getAuth();
              const currentUser = auth.currentUser;

              if (currentUser) {
                const inputValue =
                  document.getElementById("newCardsInput").value;
                if (inputValue >= 0 && inputValue <= 100) {
                  if (inputValue != item.newCards) {
                    const newCardsRef = ref(
                      database,
                      `users/${currentUser.uid}/decks/${deckKey}`
                    );

                    fetch(`${apiUrl}/rdb/updatenewcards`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        uid: currentUser.uid,
                        deckKey: deckKey,
                        newCards: inputValue,
                      }),
                    })
                      .then((response) => response.json())
                      .then((data) => {
                        GetDecks(user, setDeckNames);
                        Swal.close();
                      })
                      .catch((error) => {
                        Swal.close();
                      });
                  } else {
                    Swal.close();
                  }
                } else {
                  Swal.showValidationMessage(
                    "New Cards must be between 0 and 100"
                  );
                }
              }
            });

            deleteDeckButton.addEventListener("click", () => {
              Swal.fire({
                html: `
      <section class="decksectionpopup">
        <form id="signupForm">
          <h1 class="deckinfotitle">Delete Deck?</h1>
          
          <h4 class="deckinfodescription">Warning: You can NEVER recover this data!</h4>
          <br>
          <style>
            #newCardsLabel, #setnewcards {
              display: inline-block;
            }
          </style>
      
          
          <button class="signupbutton" id="savebutton">Delete</button>
          <br><br>
          <button class="adjustbutton" id="cancelbutton" type="submit">Cancel</button>
          
          
        </form>
      </section>
      `,
                showConfirmButton: false,
                allowOutsideClick: true,
                customClass: {
                  popup: "deckbodypopup",
                  content: "decksectionpopup",
                },

                didOpen: () => {
                  const savebutton = document.querySelector("#savebutton");
                  const cancelButton = document.querySelector("#cancelbutton");

                  savebutton.addEventListener("click", (event) => {
                    event.preventDefault();
                    const database = getDatabase();
                    const auth = getAuth();
                    const currentUser = auth.currentUser;

                    if (currentUser) {
                      delete reviewCards[item.name];
                      fetch(`${apiUrl}/rdb/removedeck`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          uid: currentUser.uid,
                          deckKey: item.tag,
                        }),
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          console.log("Deck removed successfully");

                          Swal.close();
                          if (item.tag.substring(0, 2) === "m-") {
                            window.location.reload();
                          }
                          GetDecks(user, setDeckNames);
                        })
                        .catch((error) => {
                          Swal.close();
                        });
                    }
                  });

                  cancelButton.addEventListener("click", (event) => {
                    event.preventDefault();

                    Swal.close();
                  });
                },
              });
            });
          },
        });
      });

      const learnButton = popup.querySelector("#learnButton");

      learnButton.addEventListener("click", (event) => {
        event.preventDefault();
        if (item.newCards > 0 || item.reviewCards.length > 0) {
          openLearnModal(item, treeStage, deckKey, user, setDeckNames);
        } else {
          Swal.showValidationMessage("No cards avaiable for now.");
        }
      });
    },
  });
}

function openLearnModal(item, treeStage, deckKey, user, setDeckNames) {
  let elements = document.getElementsByClassName("learnblinder");
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = "block";
  }
  Swal.fire({
    /* BELOW IS THE SWAL FIRE FOR LEANING CARDS 
  -- key -- 
  reviewCards[item.name] - get the card names (0001,0002,0003)
  item.newCards - number of new cards (15)
  item.name - language name (Japanese 1K)
  deckKey - key name (jp1k)
  */
    html: `
      <section class="learnsectionpopup">
        <form id="signupForm">
          <h1 class="learninfotitle" id="cardname"></h1>
          <button class="audiobutton" id="audiobutton" type="button" >▶</button>
          <div id="meaning-container" style="display: none;">
            <h4 class="learninfodescription" id="meaning">meaning</h4>
            <br>
          </div>
          <h4 class="learninfodescription" id="example" style="display: none;">sentence</h4>
          <h4 class="text-base text-white" id="explanation" style="display: none;">explanation</h4>
          <button class="audiobutton" id="exampleaudiobutton" style="display: none;" type="button">▶</button>     
          <div id="space-container1" style="display: none;">
            <br>
          </div>
          <button class="flipbutton" id="explainbutton" style="display: none;" type="button">Explain</button>
          <div id="space-container2" style="display: none;">
            <br>
          </div>
          <img class="wordimage" id="wordimage" src="../Languages/jp1k/images/0001.png" style="display: none; width: 300px; height: 200px; border-radius: 16px; object-fit: cover; object-position: center;">
          <div id="space-container3" style="display: none;">
            <br>
          </div>
          <div class="button-container">
            <button class="flipbutton" id="againbutton" style="display: none;" type="button">Again</button>
            <button class="flipbutton" id="goodbutton" style="display: none;" type="button">Good</button>
          </div>
          <div id="space-container4">
            <br>
          </div>
          <button class="flipbutton" id="flipbutton" type="button">Flip Card</button>
          <button class="flipbutton" id="backbutton" type="button" style="display: none;">Go Back</button>
          <div id="space-container5" style="display: block;">
            <br>
          </div>
          <button class="flipbutton" id="suspendbutton" type="button" style="display: block;">Suspend Card</button>
        </form>
        
      </section>
      <p id="hint" class="text-xs text-center text-gray-400 mt-2">Try to remember the meaning, then flip the card.</p>
    `,
    showConfirmButton: false,
    allowOutsideClick: false,
    showCloseButton: true,
    customClass: {
      popup: "learnbodypopup",
      content: "learnsectionpopup",
    },
    didOpen: async () => {
      let isCustom = false;
      if (item.tag.substring(0, 2) === "m-") {
        isCustom = true;
      }
      console.log("Deck reviewCards: " + reviewCards[item.name]);
      console.log("TEXT: " + item.text);

      let detailedEvolution = item.detailedEvolution;
      let furiganaBool = item.furigana;
      let explainBool = item.explainButton;
      console.log("Detailed Evolution: ", detailedEvolution);
      console.log("ExplainButn: ", explainBool);

      const expB = document.getElementById("explainbutton");
      const exaudioButton = document.querySelector("#exampleaudiobutton");

      // If explainButton variable is false, remove the element

      const dummyElement = document.createElement("button");
      dummyElement.style.opacity = "0";
      dummyElement.style.position = "absolute";
      document.body.appendChild(dummyElement);
      dummyElement.focus();

      document.querySelector(".swal2-close").addEventListener("click", () => {
        console.log("Close button clicked");
        GetDecks(user, setDeckNames);
        Swal.close();
      });

      let isSentenceAudio = true;
      let isImage = true;
      let cardId;

      // Add each entry from item.newLearnCards to reviewCards[item.name]
      if (isCustom) {
        cardId = customCards[item.tag][0];
      } else {
        cardId = reviewCards[item.name][0];
      }

      console.log("cardId:", cardId);

      let data;
      let customWordData;
      let code = item.tag;
      let interval;

      if (isCustom) {
        try {
          const response = await fetch(`${apiUrl}/rdb/getcustomcard`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: user.uid,
              deckKey: item.tag,
              cardId: String(cardId), // Convert cardId to a string
            }),
          });

          const result = await response.json();
          console.log(result);
          customWordData = result;
        } catch (error) {
          console.error("Error:", error);
        }
      }

      data = JSON.parse(item.text);
      console.log(data + code);
      console.log(JSON.stringify(data) + code);
      if (
        data &&
        data[code] &&
        data[code][cardId] &&
        data[code][cardId].interval !== undefined
      ) {
        interval = data[code][cardId].interval;
      } else {
        console.error(
          "data, data[code], data[code][cardId], or data[code][cardId].interval is undefined"
        );
      }

      console.log("Interval: ", interval);
      const titleElement1 = document.querySelector("#cardname");
      const audioButton = document.querySelector("#audiobutton");

      let deckModule;
      let deck;
      let word;
      let meaning;
      let example;
      let explanation;
      if (isCustom) {
        deckModule = customWordData;
        console.log("Custom deck data: ", deckModule);
        deck = deckModule; // assign deckModule directly to deck
        word = deck.word;
        meaning = deck.meaning;
        example = deck.exampleSentence;
        explanation = deck.exampleMeaning;
      } else {
        deckModule = await import(`../Languages/${deckKey}/${deckKey}.json`);
        deck = deckModule.default; // assign deckModule.default to deck
        word = deck[cardId].word;
        meaning = deck[cardId].meaning;
        example = deck[cardId].example;
        explanation = deck[cardId].explanation;
      }
      console.log(deck);

      console.log("example: " + example);

      if (furiganaBool && item.tag == "jp1k") {
        word = word.replace(
          /(\p{Script=Han}+)\((\p{Script=Hiragana}+)\)/gu,
          "<ruby>$1<rt>$2</rt></ruby>"
        );

        example = example.replace(
          /(\p{Script=Han}+)\((\p{Script=Hiragana}+)\)/gu,
          "<ruby>$1<rt>$2</rt></ruby>"
        );
      }

      if (item.tag == "mn1k") {
        // Check if the word contains parentheses
        let match = word.match(/\((.*?)\)/);
        if (match) {
          // Extract the pronunciation from the parentheses
          const pinyin = match[1];
          // Remove the parentheses and the pronunciation from the word
          const sentence = word.replace(match[0], "");
          // Replace the original sentence with a ruby annotation
          word = `<ruby class='clickable-ruby'>${sentence}<rt>click for reading</rt><rt style='display: none;'>${pinyin}</rt></ruby>`;
        } else {
          console.error(`Word does not contain parentheses: ${word}`);
        }

        // Do the same for the example
        match = example.match(/\((.*?)\)/);
        if (match) {
          // Extract the pronunciation from the parentheses
          const pinyin = match[1];
          // Remove the parentheses and the pronunciation from the example
          const sentence = example.replace(match[0], "");
          // Replace the original sentence with a ruby annotation
          example = `<ruby>${sentence}<rt>${pinyin}</rt></ruby>`;
        } else {
          console.error(`Example does not contain parentheses: ${example}`);
        }
      }

      // Add this JavaScript to handle the click event
      document.addEventListener("click", function (e) {
        if (e.target.closest(".clickable-ruby")) {
          const rtElements = e.target
            .closest(".clickable-ruby")
            .querySelectorAll("rt");
          if (rtElements.length === 2) {
            rtElements[0].textContent = rtElements[1].textContent; // Set the text to the actual reading
            rtElements[1].style.display = "none"; // Hide the actual reading
          }
        }
      });

      document.querySelector(".learninfotitle").innerHTML = word;
      document.querySelector(".learninfodescription").innerHTML = meaning;

      document.getElementById("example").innerHTML = example;

      let titleElement = document.querySelector(".learninfotitle");

      document.querySelector(".learninfotitle").innerHTML = word;
      document.querySelector(".learninfodescription").textContent = meaning;

      document.getElementById("example").innerHTML = example;

      document.querySelector("#explanation").textContent = explanation;

      if (!isCustom) {
        if (interval >= 7 && treeStage == 4) {
          // Active recall evolution
          titleElement.innerHTML = `Recall the word for: <br> <strong>${deck[cardId].meaning}</strong>`;
          audioButton.style.display = "none"; // Hide audio button on the front
          document
            .querySelector("#flipbutton")
            .addEventListener("click", () => {
              titleElement.textContent = deck[cardId].word; // Show normal card on the back
              audioButton.style.display = "block"; // Show audio button on the back
            });
        }

        try {
          const audioModule = await import(
            `../Languages/${deckKey}/audio/${deck[cardId].word}.mp3`
          );
          const audio = new Audio(audioModule.default);

          document
            .querySelector("#audiobutton")
            .addEventListener("click", () => {
              audio.play();
            });

          if (
            detailedEvolution &&
            interval >= 4 &&
            interval < 7 &&
            treeStage < 4
          ) {
            // Listening card
            titleElement.textContent = "What word is this?";
            document
              .querySelector("#flipbutton")
              .addEventListener("click", () => {
                titleElement.textContent = deck[cardId].word; // Set back to the word after flipping
              });
          }
        } catch (error) {
          const audiobutton = document.querySelector("#audiobutton");
          audiobutton.style.display = "none";
        }

        try {
          let sanitizedExample = deck[cardId].example.replace(/\?/g, "");
          sanitizedExample = sanitizedExample.trim(); // Add this line to remove leading and trailing whitespace

          const exampleAudioModule = await import(
            `../Languages/${deckKey}/sentences/${sanitizedExample}.mp3`
          );

          const exampleAudio = new Audio(exampleAudioModule.default);

          document
            .querySelector("#exampleaudiobutton")
            .addEventListener("click", () => {
              exampleAudio.play();
            });
        } catch (error) {
          console.log("couldnt load sentene audio");
          console.log(
            `../Languages/${deckKey}/sentences/${encodeURIComponent(
              deck[cardId].example
            )}.mp3`
          );
          isSentenceAudio = false;
        }
      } else {
        audioButton.style.display = "none";
        exaudioButton.style.display = "none";

        document.querySelector("#explainbutton").style.display = "none";
      }

      try {
        const imageUrl = `https://github.com/sudotolanguages/sudoto-public/blob/main/DeckImages/${deckKey}/${cardId}.png?raw=true`;

        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          const wordImageElement = document.querySelector("#wordimage");
          wordImageElement.src = img.src;
          wordImageElement.style.width = "100%";
          wordImageElement.style.height = "auto";
        };
        img.onerror = async () => {
          try {
            const gifModule = await import(
              `../Languages/${deckKey}/images/${word}.GIF`
            );

            if (gifModule) {
              const img = new Image();
              img.src = gifModule.default;
              const wordImageElement = document.querySelector("#wordimage");
              wordImageElement.src = img.src;
              wordImageElement.style.width = "100%";
              wordImageElement.style.height = "auto";
            }
          } catch (error) {
            console.log("Neither PNG nor GIF exists");
            isImage = false;
          }
        };
      } catch (error) {}

      const database = getDatabase();
      const auth = getAuth();
      const currentUser = auth.currentUser;

      /* implement intervals, and first time on a card  */
      if (currentUser) {
        if (isCustom) {
          deckKey = item.tag;
        }
        const goodButton = document.getElementById("goodbutton");
        goodButton.addEventListener("click", function () {
          const now = new Date().toISOString();

          const wordRef = ref(
            database,
            `users/${currentUser.uid}/decks/${deckKey}/${cardId}`
          );

          const pointsRef = ref(
            database,
            `users/${currentUser.uid}/decks/${deckKey}`
          );

          if (isCustom) {
            deckKey = item.tag;
          }

          console.log(
            "Getting word... DECK KEY IS: " + deckKey + " CARD ID IS: " + cardId
          );
          fetch(`${apiUrl}/rdb/get-word`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: currentUser.uid,
              deckKey: deckKey,
              cardId: cardId,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Data: ", data);
              if (data && data.name != "ReqlNonExistenceError") {
                let interval = data.interval;

                if (interval <= 7) {
                  interval++;
                  console.log("updating for good button...");
                  fetch(`${apiUrl}/rdb/update-word`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      uid: currentUser.uid,
                      deckKey: deckKey,
                      cardId: cardId,
                      lastReviewed: now,
                      interval: interval,
                    }),
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      console.log("Success:", data);
                      console.log("Updating points...");
                      fetch(`${apiUrl}/rdb/update-points`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          uid: currentUser.uid,
                          deckKey: deckKey,
                          points: item.points + interval,
                        }),
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          handleNextCard(
                            item,
                            treeStage,
                            deckKey,
                            user,
                            setDeckNames
                          );
                        })
                        .catch((error) => {
                          console.error("Error updating document: ", error);
                          GetDecks(user, setDeckNames);
                          Swal.close();
                        });
                    })
                    .catch((error) => {
                      console.error("Error updating document: ", error);
                      GetDecks(user, setDeckNames);
                      Swal.close();
                    });
                }
              } else if (data.name === "ReqlNonExistenceError") {
                console.log("Setting word...");
                fetch(`${apiUrl}/rdb/set-word`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    uid: currentUser.uid,
                    deckKey: deckKey,
                    cardId: cardId,
                    lastReviewed: now,
                    interval: 0,
                    dateLearned: now,
                    isSuspended: false,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    handleNextCard(
                      item,
                      treeStage,
                      deckKey,
                      user,
                      setDeckNames
                    );
                  })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                    GetDecks(user, setDeckNames);
                    Swal.close();
                  });
              }
            })
            .catch((error) => {
              console.error(error);
              handleNextCard(item, treeStage, deckKey, user, setDeckNames);
            });
        });

        function handleNextCard(item, treeStage, deckKey, user, setDeckNames) {
          if (isCustom) {
            customCards[item.tag] = customCards[item.tag].filter(
              (id) => id !== cardId
            );
          } else {
            reviewCards[item.name] = reviewCards[item.name].filter(
              (id) => id !== cardId
            );
          }

          console.log(
            "AGAAA " +
              (isCustom ? customCards[item.tag] : reviewCards[item.name])
          );

          if (
            (isCustom &&
              customCards[item.tag] &&
              customCards[item.tag].length > 0) ||
            (!isCustom &&
              reviewCards[item.name] &&
              reviewCards[item.name].length > 0)
          ) {
            if (isCustom) {
              // Update item to reflect the next card in the custom deck
              const nextCardId = customCards[item.tag][0];
              item.text = JSON.stringify({
                [item.tag]: { [nextCardId]: data },
              });
            }
            console.log("should fire next swal for custom");
            openLearnModal(item, treeStage, deckKey, user, setDeckNames);
          } else {
            if (item.content !== null && item.content !== undefined) {
              const percentage = ((item.cards / item.words) * 100).toFixed(2);
              let contentIndex = Math.max(
                1,
                Math.min(10, Math.ceil(percentage / 10))
              );
              let contentArray = item.content[contentIndex];

              if (!contentArray || contentArray.length < 3) {
                console.log("Not enough content to display");
                GetDecks(user, setDeckNames);
                Swal.close();
                return;
              }

              let randomIndices = [];
              while (randomIndices.length < 3) {
                let r = Math.floor(Math.random() * contentArray.length);
                if (randomIndices.indexOf(r) === -1) randomIndices.push(r);
              }

              let randomEntries = randomIndices
                .map(
                  (i) =>
                    `<p class="text-base text-white pb-2">• ${contentArray[i]}</p>`
                )
                .join("");

              if (!isCustom) {
                Swal.fire({
                  html: `
                    <section class="decksectionpopup">
                      <form id="signupForm">
                        <h1 class="deckinfotitle">AI Content Recommendations</h1>
                        <h4 class="text-base text-white">Learning the words are the seeds to fluency, the most important part is watering the seeds through immersion!</h4>
                        <br>
                        ${randomEntries}
                        <style>
                          #newCardsLabel, #setnewcards {
                            display: inline-block;
                          }
                        </style>
                        <button class="signupbutton" id="cancelbutton" type="submit">Close</button>
                      </form>
                    </section>
                  `,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  customClass: {
                    popup: "deckbodypopup",
                    content: "decksectionpopup",
                  },
                  didOpen: () => {
                    document
                      .getElementById("cancelbutton")
                      .addEventListener("click", (event) => {
                        event.preventDefault();
                        Swal.close();
                        closeLearnblinder();
                        GetDecks(user, setDeckNames);
                      });
                  },
                });
              } else {
                GetDecks(user, setDeckNames);
                Swal.close();
                closeLearnblinder();
              }
            } else {
              GetDecks(user, setDeckNames);
              Swal.close();
              closeLearnblinder();
            }
          }
        }

        function closeLearnblinder() {
          let elements = document.getElementsByClassName("learnblinder");
          for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "none";
          }
        }

        const againbutton = document.getElementById("againbutton");
        againbutton.addEventListener("click", function () {
          const now = new Date().toISOString();

          const wordRef = ref(
            database,
            `users/${currentUser.uid}/decks/${deckKey}/${cardId}`
          );

          console.log("Getting word for againbutton...");
          fetch(`${apiUrl}/rdb/get-word`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: currentUser.uid,
              deckKey: deckKey,
              cardId: cardId,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data && data.name != "ReqlNonExistenceError") {
                let interval = data.interval;
                if (interval >= 3) {
                  interval -= 3;
                } else {
                  interval -= interval;
                }

                if (isCustom) {
                  customCards[item.tag] = customCards[item.tag].filter(
                    (id) => id !== cardId
                  );
                  if (customCards[item.tag].length > 0) {
                    const index = Math.floor(
                      Math.random() * customCards[item.tag].length
                    );
                    customCards[item.tag].splice(index, 0, cardId);
                  } else {
                    customCards[item.tag].push(cardId);
                  }
                } else {
                  reviewCards[item.name] = reviewCards[item.name].filter(
                    (id) => id !== cardId
                  );
                  if (reviewCards[item.name].length > 12) {
                    const index =
                      Math.floor(
                        Math.random() * (reviewCards[item.name].length - 1)
                      ) + 1;
                    reviewCards[item.name].splice(index, 0, cardId);
                  } else {
                    reviewCards[item.name].push(cardId);
                  }
                }

                console.log("Updating againbutton word...");
                fetch(`${apiUrl}/rdb/update-word2`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    uid: currentUser.uid,
                    deckKey: deckKey,
                    cardId: cardId,
                    interval: interval,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.status === "success") {
                      console.log("Document successfully updated!");
                    }

                    if (
                      (isCustom &&
                        customCards[item.tag] &&
                        customCards[item.tag].length > 0) ||
                      (!isCustom &&
                        reviewCards[item.name] &&
                        reviewCards[item.name].length > 0)
                    ) {
                      if (isCustom) {
                        // Update item to reflect the next card in the custom deck
                        const nextCardId = customCards[item.tag][0];
                        item.text = JSON.stringify({
                          [item.tag]: { [nextCardId]: data },
                        });
                      }
                      openLearnModal(
                        item,
                        treeStage,
                        deckKey,
                        user,
                        setDeckNames
                      );
                    } else {
                      GetDecks(user, setDeckNames);
                      Swal.close();
                    }
                  })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                    GetDecks(user, setDeckNames);
                    Swal.close();
                  });
              } else if (data.name === "ReqlNonExistenceError") {
                console.log("Setting word...");
                fetch(`${apiUrl}/rdb/set-word`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    uid: currentUser.uid,
                    deckKey: deckKey,
                    cardId: cardId,
                    lastReviewed: now,
                    interval: 0,
                    dateLearned: now,
                    isSuspended: false,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (isCustom) {
                      customCards[item.tag] = customCards[item.tag].filter(
                        (id) => id !== cardId
                      );
                      if (customCards[item.tag].length > 0) {
                        const index = Math.floor(
                          Math.random() * customCards[item.tag].length
                        );
                        customCards[item.tag].splice(index, 0, cardId);
                      } else {
                        customCards[item.tag].push(cardId);
                      }
                    } else {
                      reviewCards[item.name] = reviewCards[item.name].filter(
                        (id) => id !== cardId
                      );
                      if (reviewCards[item.name].length > 12) {
                        const index =
                          Math.floor(
                            Math.random() * (reviewCards[item.name].length - 1)
                          ) + 1;
                        reviewCards[item.name].splice(index, 0, cardId);
                      } else {
                        reviewCards[item.name].push(cardId);
                      }
                    }

                    if (
                      (isCustom &&
                        customCards[item.tag] &&
                        customCards[item.tag].length > 0) ||
                      (!isCustom &&
                        reviewCards[item.name] &&
                        reviewCards[item.name].length > 0)
                    ) {
                      if (isCustom) {
                        // Update item to reflect the next card in the custom deck
                        const nextCardId = customCards[item.tag][0];
                        item.text = JSON.stringify({
                          [item.tag]: { [nextCardId]: data },
                        });
                      }
                      openLearnModal(
                        item,
                        treeStage,
                        deckKey,
                        user,
                        setDeckNames
                      );
                    } else {
                      GetDecks(user, setDeckNames);
                      Swal.close();
                    }
                  })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                    GetDecks(user, setDeckNames);
                    Swal.close();
                  });
              }
            })
            .catch((error) => {
              console.error(error);
              if (
                isCustom &&
                customCards[item.tag] &&
                customCards[item.tag].length > 0
              ) {
                openLearnModal(item, treeStage, deckKey, user, setDeckNames);
              } else if (
                reviewCards[item.name] &&
                reviewCards[item.name].length > 0
              ) {
                openLearnModal(item, treeStage, deckKey, user, setDeckNames);
              } else {
                GetDecks(user, setDeckNames);
                Swal.close();
              }
            });
        });

        const suspendButton = document.getElementById("suspendbutton");
        suspendButton.addEventListener("click", function () {
          const now = new Date().toISOString();

          const wordRef = ref(
            database,
            `users/${currentUser.uid}/decks/${deckKey}/${cardId}`
          );

          //let card = reviewCards[item.name].shift();
          console.log("Getting word for suspendbutton...");
          fetch(`${apiUrl}/rdb/get-word2`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: currentUser.uid,
              deckKey: deckKey,
              cardId: cardId,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data && data.name != "ReqlNonExistenceError") {
                fetch("/suspend-word", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    uid: currentUser.uid,
                    deckKey: deckKey,
                    cardId: cardId,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.status === "success") {
                      console.log("Word successfully suspended!");
                    }
                    reviewCards[item.name] = reviewCards[item.name].filter(
                      (id) => id !== cardId
                    );
                    if (
                      reviewCards[item.name] &&
                      reviewCards[item.name].length > 0
                    ) {
                      openLearnModal(
                        item,
                        treeStage,
                        deckKey,
                        user,
                        setDeckNames
                      );
                    } else {
                      GetDecks(user, setDeckNames);
                      Swal.close();
                    }
                  })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                    GetDecks(user, setDeckNames);
                    Swal.close();
                  });
              } else if (data.name === "ReqlNonExistenceError") {
                console.log("Setting word for suspendbutton...");
                fetch(`${apiUrl}/rdb/suspendset`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    uid: currentUser.uid,
                    deckKey: deckKey,
                    cardId: cardId,
                    lastReviewed: now,
                    interval: 0,
                    dateLearned: now,
                    isSuspended: true,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.status === "success") {
                      console.log("Document successfully updated!");
                    }
                    reviewCards[item.name] = reviewCards[item.name].filter(
                      (id) => id !== cardId
                    );
                    if (
                      reviewCards[item.name] &&
                      reviewCards[item.name].length > 0
                    ) {
                      openLearnModal(
                        item,
                        treeStage,
                        deckKey,
                        user,
                        setDeckNames
                      );
                    } else {
                      GetDecks(user, setDeckNames);
                      Swal.close();
                    }
                  })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                    GetDecks(user, setDeckNames);
                    Swal.close();
                  });
              }
            })
            .catch((error) => {
              console.error(error);
              //reviewCards[item.name].unshift(card);
              if (reviewCards[item.name] && reviewCards[item.name].length > 0) {
                openLearnModal(item, treeStage, deckKey, user, setDeckNames);
              } else {
                GetDecks(user, setDeckNames);
                Swal.close();
              }
            });
        });
      } else {
        console.log("No user is signed in");
      }

      document.querySelector(".swal2-container").classList.add("learnSwal");

      document.querySelector("#flipbutton").addEventListener("click", () => {
        const elementsToToggle = [
          "#meaning-container",
          "#example",
          "#goodbutton",
          "#againbutton",
          "#explainbutton",
          "#exampleaudiobutton",
          "#wordimage",
          "#space-container1",
          "#space-container2",
          "#space-container3",
        ];

        elementsToToggle.forEach((selector) => {
          if (!isSentenceAudio && selector === "#exampleaudiobutton") {
            return; // Skip the current iteration if isSentenceAudio is false and the current selector is #exampleaudiobutton
          }

          if (!explainBool && selector === "#explainbutton") {
            return; // Skip the current iteration if isSentenceAudio is false and the current selector is #exampleaudiobutton
          }

          if (!isImage && selector === "#wordimage") {
            return; // Skip the current iteration if isImage is false and the current selector is #wordimage
          }

          if (!isImage && selector === "#space-container3") {
            return; // Skip the current iteration if isImage is false and the current selector is #wordimage
          }

          if (isCustom && selector === "#exampleaudiobutton") {
            return; // Skip the current iteration if isCustom is true and the current selector is #exampleaudiobutton
          }

          const element = document.querySelector(selector);
          element.style.display =
            element.style.display === "none" ? "block" : "none";
        });

        const flipbutton = document.querySelector("#flipbutton");
        flipbutton.style.display = "none";

        const spacecontainer4 = document.querySelector("#space-container4");
        spacecontainer4.style.display = "none";

        const spacecontainer5 = document.querySelector("#space-container5");
        spacecontainer5.style.display = "none";

        const suspendbutton = document.querySelector("#suspendbutton");
        suspendbutton.style.display = "none";

        document.querySelector("#hint").textContent =
          "Press good if you knew it, or again if you didn't.";
      });

      document.querySelector("#backbutton").addEventListener("click", () => {
        const elementsToHide = [
          "#explanation",
          "#backbutton",
          "#suspendbutton",
          "#space-container5",
        ];

        const elementsToShow = [
          "#meaning-container",
          "#example",
          "#goodbutton",
          "#againbutton",
          "#explainbutton",
          "#exampleaudiobutton",
          "#wordimage",
          "#space-container1",
          "#space-container2",
          "#space-container3",
        ];

        elementsToHide.forEach((selector) => {
          const element = document.querySelector(selector);
          element.style.display = "none";
        });

        elementsToShow.forEach((selector) => {
          if (!isSentenceAudio && selector === "#exampleaudiobutton") {
            return; // Skip the current iteration if isSentenceAudio is false and the current selector is #exampleaudiobutton
          }

          if (!explainBool && selector === "#explainbutton") {
            return; // Skip the current iteration if isSentenceAudio is false and the current selector is #exampleaudiobutton
          }

          if (!isImage && selector === "#wordimage") {
            return; // Skip the current iteration if isImage is false and the current selector is #wordimage
          }

          if (isCustom && selector === "#exampleaudiobutton") {
            return; // Skip the current iteration if isCustom is true and the current selector is #exampleaudiobutton
          }

          if (!isImage && selector === "#space-container3") {
            return; // Skip the current iteration if isImage is false and the current selector is #wordimage
          }

          const element = document.querySelector(selector);
          element.style.display = "block";
        });

        const flipbutton = document.querySelector("#flipbutton");
        flipbutton.style.display = "none";

        const spacecontainer4 = document.querySelector("#space-container4");
        spacecontainer4.style.display = "none";
      });

      document.querySelector("#explainbutton").addEventListener("click", () => {
        const elementsToHide = [
          "#meaning-container",
          "#example",
          "#goodbutton",
          "#againbutton",
          "#explainbutton",
          "#exampleaudiobutton",
          "#wordimage",
          "#space-container1",
          "#space-container2",
          "#space-container3",
        ];

        elementsToHide.forEach((selector) => {
          const element = document.querySelector(selector);
          element.style.display = "none";
        });

        const explanation = document.querySelector("#explanation");
        explanation.style.display = "block";
        const backbutton = document.querySelector("#backbutton");
        backbutton.style.display = "block";
        const spacecontainer5 = document.querySelector("#space-container5");
        spacecontainer5.style.display = "block";
        const suspendbutton = document.querySelector("#suspendbutton");
        suspendbutton.style.display = "block";
        const spacecontainer4 = document.querySelector("#space-container4");
        spacecontainer4.style.display = "block";
      });

      // CARD EVOLUTION CODE

      console.log("tree stage: ", treeStage);

      if (!explainBool && expB) {
        expB.remove();
      }
    },
  });
}

function pickerPopup(langlist, user, setDeckNames) {
  const options = Object.keys(langlist)
    .map(
      (key, index) => `<option value="${key}">${langlist[key].name}</option>`
    )
    .join("");

  Swal.fire({
    html: `
<section class="decksectionpopup">
<form id="signupForm">
<h1 class="deckinfotitle">Select New Deck</h1>

<h4
  
  class="deckinfodescription"
  id="deckinfodescription"
>${langlist[Object.keys(langlist)[0]].description}</h4>
<br>
<style>
#newCardsLabel, #setnewcards {
  display: inline-block;
}
</style>

<select class="langpicker" id="langlist" style="width: 200px; text-align: center; border-radius: 50px; border: none;">
  ${options}
</select>
<br><br>

<button class="signupbutton" id="savebutton">Add Deck</button>

</form>
</section>
`,
    showConfirmButton: false,
    allowOutsideClick: true,
    customClass: {
      popup: "deckbodypopup",
      content: "decksectionpopup",
    },

    didOpen: () => {
      document
        .getElementById("langlist")
        .addEventListener("change", (event) => {
          document.getElementById("deckinfodescription").textContent =
            langlist[event.target.value].description;
        });

      const savebutton = document.querySelector("#savebutton");

      savebutton.addEventListener("click", (event) => {
        event.preventDefault();
        const database = getDatabase();
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
          const selectedLang = document.getElementById("langlist").value;

          const newCardsRef = ref(
            database,
            `users/${currentUser.uid}/decks/${selectedLang}`
          );

          let deckKey = selectedLang;

          // Assuming reviewcards is available in this scope
          if (reviewCards.hasOwnProperty(langlist[selectedLang].name)) {
            console.log(reviewCards);
            Swal.showValidationMessage("You already have this deck.");
          } else {
            fetch(`${apiUrl}/rdb/setnewdeckcards`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                uid: currentUser.uid,
                deckKey: deckKey,
                newCards: 10,
                points: 0,
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                GetDecks(user, setDeckNames);
                Swal.close();
              })
              .catch((error) => {
                Swal.close();
              });
          }
        }
      });
    },
  });
}

function setStreakData() {
  console.log("Streak Data: ", streakData);

  streak1 = 0;

  const uniqueSortedDates = Array.from(
    new Set(streakData.map((date) => date.toISOString()))
  ).sort((a, b) => b.localeCompare(a));

  // Starting the streak count
  let previousDate1 = new Date(uniqueSortedDates[0]);

  for (let i = 1; i < uniqueSortedDates.length; i++) {
    const currentDate1 = new Date(uniqueSortedDates[i]);
    const expectedDate = new Date(previousDate1);
    expectedDate.setDate(expectedDate.getDate() - 1);

    if (
      currentDate1.toISOString().split("T")[0] ===
      expectedDate.toISOString().split("T")[0]
    ) {
      streak1++;
      previousDate1 = currentDate1;
    } else {
      break; // Stop if the next date is not consecutive
    }
  }

  if (streak1 > 0) {
    streak1++;
  }

  // Set the calculated streak in the DOM
  document.getElementById("streak").textContent = streak1.toString();

  const infoPopupButton = document.querySelector("#infopopupbutton");

  infoPopupButton.addEventListener("click", () => {
    Swal.fire({
      html: `
        <section class="decksectionpopup">
          <form id="signupForm">
            <h1 class="deckinfotitle">${totalPoints} total stars</h1>
            <h1 class="deckinfodescription">Collect stars through reviews!</h4>
            
            <br>
            <h1 id="calstreak" class="deckinfotitle">${streak1} day streak</h4>
            <h1 class="deckinfodescription">Increase your streak with new cards!</h4>
            <br>
            <div id="calendar" ></div>
            <br>
            <style>
              #newCardsLabel, #setnewcards {
                display: inline-block;
              }
            </style>
        
            
            
            
          </form>
        </section>
        `,
      showConfirmButton: false,
      allowOutsideClick: true,
      customClass: {
        popup: "deckbodypopup",
        content: "decksectionpopup",
      },

      didOpen: () => {
        const formattedStreakData = streakData.map(
          (date) =>
            `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
              -2
            )}-${("0" + date.getDate()).slice(-2)}`
        );

        ReactDOM.render(
          <Calendar
            tileClassName={({ date, view }) =>
              formattedStreakData.includes(
                `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
                  -2
                )}-${("0" + date.getDate()).slice(-2)}`
              )
                ? "highlight"
                : null
            }
          />,
          document.getElementById("calendar")
        );
      },
    });
  });
  /*ReactDOM.render(
    <Calendar
      tileClassName={({ date, view }) =>
        streakData.includes(
          `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
            "0" + date.getDate()
          ).slice(-2)}`
        )
          ? "highlight"
          : null
      }
    />,
    document.getElementById("calendar")
  );*/
}

function beginnerAiPopup() {
  let diff = "beginner";
  if (aiCards.length <= 0) {
    // aicards is empty
    Swal.fire({
      html: `
  <section class="decksectionpopup">
  <form id="signupForm">
  <h1 class="deckinfotitle">No Cards Available</h1>
  
  <h4 class="deckinfodescription">You can only practice with AI once you have just learned a card, come back once you've learned new cards.</h4>
  <br>
  <style>
  #newCardsLabel, #setnewcards {
    display: inline-block;
  }
  </style>
  
  
  
  <button class="signupbutton" id="closebutton">Close</button>
  
  </form>
  </section>
  `,
      showConfirmButton: false,
      allowOutsideClick: true,
      customClass: {
        popup: "deckbodypopup",
        content: "decksectionpopup",
      },

      didOpen: () => {
        const closeButton = document.querySelector("#closebutton");

        closeButton.addEventListener("click", (event) => {
          event.preventDefault();
          Swal.close();
        });
      },
    });
  } else {
    Swal.fire({
      html: `
  <section class="decksectionpopup">
  <form id="signupForm">
  <h1 class="deckinfotitle">${aiCards.length} available</h1>
  <h4 class="deckinfodescription">Practice your new cards from today with AI generated input practice!</h4>
  <br>
  <h4 class="deckinfodescription">Warning: AI is currently in beta and may not output correctly or work properly at times.</h4>
  <br>
  <style>
  #newCardsLabel, #setnewcards {
    display: inline-block;
  }
  </style>
  
  
  
  <button class="signupbutton" id="closebutton">Practice</button>
  
  </form>
  </section>
  `,
      showConfirmButton: false,
      allowOutsideClick: true,
      customClass: {
        popup: "deckbodypopup",
        content: "decksectionpopup",
      },

      didOpen: () => {
        const closeButton = document.querySelector("#closebutton");

        closeButton.addEventListener("click", (event) => {
          event.preventDefault();
          intermediateAi(diff);
        });
      },
    });
  }
}

function intermediateAiPopup() {
  let diff = "intermediate";
  if (aiCards.length <= 0) {
    // aicards is empty
    Swal.fire({
      html: `
  <section class="decksectionpopup">
  <form id="signupForm">
  <h1 class="deckinfotitle">No Cards Available</h1>
  
  <h4 class="deckinfodescription">You can only practice with AI once you have just learned a card, come back once you've learned new cards.</h4>
  <br>
  <style>
  #newCardsLabel, #setnewcards {
    display: inline-block;
  }
  </style>
  
  
  
  <button class="signupbutton" id="closebutton">Close</button>
  
  </form>
  </section>
  `,
      showConfirmButton: false,
      allowOutsideClick: true,
      customClass: {
        popup: "deckbodypopup",
        content: "decksectionpopup",
      },

      didOpen: () => {
        const closeButton = document.querySelector("#closebutton");

        closeButton.addEventListener("click", (event) => {
          event.preventDefault();
          Swal.close();
        });
      },
    });
  } else {
    Swal.fire({
      html: `
  <section class="decksectionpopup">
  <form id="signupForm">
  <h1 class="deckinfotitle">${aiCards.length} available</h1>
  <h4 class="deckinfodescription">Practice your new cards from today with AI generated input practice!</h4>
  <br>
  <h4 class="deckinfodescription">Warning: AI is currently in beta and may not output correctly or work properly at times.</h4>
  <br>
  <style>
  #newCardsLabel, #setnewcards {
    display: inline-block;
  }
  </style>
  
  
  
  <button class="signupbutton" id="closebutton">Practice</button>
  
  </form>
  </section>
  `,
      showConfirmButton: false,
      allowOutsideClick: true,
      customClass: {
        popup: "deckbodypopup",
        content: "decksectionpopup",
      },

      didOpen: () => {
        const closeButton = document.querySelector("#closebutton");

        closeButton.addEventListener("click", (event) => {
          event.preventDefault();
          intermediateAi(diff);
        });
      },
    });
  }
}

function expertAiPopup() {
  let diff = "expert";
  if (aiCards.length <= 0) {
    // aicards is empty
    Swal.fire({
      html: `
  <section class="decksectionpopup">
  <form id="signupForm">
  <h1 class="deckinfotitle">No Cards Available</h1>
  
  <h4 class="deckinfodescription">You can only practice with AI once you have just learned a card, come back once you've learned new cards.</h4>
  <br>
  <style>
  #newCardsLabel, #setnewcards {
    display: inline-block;
  }
  </style>
  
  
  
  <button class="signupbutton" id="closebutton">Close</button>
  
  </form>
  </section>
  `,
      showConfirmButton: false,
      allowOutsideClick: true,
      customClass: {
        popup: "deckbodypopup",
        content: "decksectionpopup",
      },

      didOpen: () => {
        const closeButton = document.querySelector("#closebutton");

        closeButton.addEventListener("click", (event) => {
          event.preventDefault();
          Swal.close();
        });
      },
    });
  } else {
    Swal.fire({
      html: `
  <section class="decksectionpopup">
  <form id="signupForm">
  <h1 class="deckinfotitle">${aiCards.length} available</h1>
  <h4 class="deckinfodescription">Practice your new cards from today with AI generated input practice!</h4>
  <br>
  <h4 class="deckinfodescription">Warning: AI is currently in beta and may not output correctly or work properly at times.</h4>
  <br>
  <style>
  #newCardsLabel, #setnewcards {
    display: inline-block;
  }
  </style>
  
  
  
  <button class="signupbutton" id="closebutton">Practice</button>
  
  </form>
  </section>
  `,
      showConfirmButton: false,
      allowOutsideClick: true,
      customClass: {
        popup: "deckbodypopup",
        content: "decksectionpopup",
      },

      didOpen: () => {
        const closeButton = document.querySelector("#closebutton");

        closeButton.addEventListener("click", (event) => {
          event.preventDefault();
          intermediateAi(diff);
        });
      },
    });
  }
}

function intermediateAi(diff) {
  let cardId = aiCards[0];
  let wordsplit = cardId.split(" ");
  wordsplit = wordsplit.slice(0, -2);
  let cardWord = wordsplit.join(" ");
  Swal.fire({
    html: `
  <section class="decksectionpopup">
  <form id="signupForm">
  <h1 class="deckinfotitle">${cardWord}</h1><br>
  
  <h4 class="deckinfodescription" id="aioutput">Waiting for AI...</h4>
  <br>
  <style>
  #newCardsLabel, #setnewcards {
    display: inline-block;
  }
  </style>

  <div id="meaning-container" style="display: none;">
  <input type="text" id="inputBox" placeholder="Type the meaning here..." maxlength="100" style="border-radius: 50px; border: none; height: 30px; padding-left: 10px; width: 80%;">
  <br><br>
  <button class="signupbutton" id="gradebutton" style="width: 80%;">Grade interpretation</button>
  <button class="signupbutton" id="continuebutton" style="display: none; width: 80%;">Continue</button>
  </div>
  
  
  <button class="signupbutton" id="closebutton">Close</button>
  
  </form>
  </section>
  `,
    showConfirmButton: false,
    allowOutsideClick: true,
    customClass: {
      popup: "deckbodypopup",
      content: "decksectionpopup",
    },

    didOpen: async () => {
      const closeButton = document.querySelector("#closebutton");

      closeButton.addEventListener("click", (event) => {
        event.preventDefault();
        Swal.close();
      });

      const response = await fetch(`${apiUrl}/api/${diff}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cardId }),
      });

      const responseText = await response.text();
      console.log("Raw response:", responseText);

      try {
        const data = JSON.parse(responseText);

        const aiOutputElement = document.querySelector("#aioutput");
        aiOutputElement.textContent = data.message;

        const inputBox = document.querySelector("#meaning-container");
        inputBox.style.display = "block";

        const closeButton = document.querySelector("#closebutton");
        closeButton.style.display = "none";

        const gradeButton = document.querySelector("#gradebutton");
        gradeButton.addEventListener("click", async (event) => {
          event.preventDefault();

          // Get the text from the inputBox and the content of aioutput
          const inputText = document.querySelector("#inputBox").value;
          const aiOutputText = document.querySelector("#aioutput").textContent;

          if (!inputText.trim()) {
            Swal.showValidationMessage("Please translate before grading.");
          } else {
            gradeButton.style.display = "none";
            // Send another request when gradebutton is clicked
            const response = await fetch(`${apiUrl}/api/grade`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ inputText, aiOutputText }), // Include inputText and aiOutputText in the request body
            });

            const responseText = await response.text();
            console.log("Raw response 2:", responseText);

            // Hide the meaning-container
            document.querySelector("#meaning-container").style.display = "none";

            // Set #aioutput to the response
            const data2 = JSON.parse(responseText);

            const aiOutputElement = document.querySelector("#aioutput");
            aiOutputElement.innerHTML =
              "Original sentence:<br>" +
              aiOutputText +
              "<br><br>Your translation:<br>" +
              inputText +
              "<br><br>" +
              data2.message;

            aiCards.shift();

            if (aiCards.length > 0) {
              document.querySelector("#continuebutton").style.display = "block";
              const continueButton = document.querySelector("#continuebutton");
              continueButton.addEventListener("click", async (event) => {
                event.preventDefault();
                intermediateAi(diff);
              });
            } else {
              document.querySelector("#closebutton").style.display = "block";
            }
          }
        });
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    },
  });
}

/*export function getStreak() {
  return streak;
}

export function getStreakData() {
  return calendarData;
}

export function getPoints() {
  return totalPoints;
}*/

export default DeckView;
