import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import Swal from "sweetalert2";
import { isEmail } from "validator"; // adjust the path as needed

const firebaseConfig = {
  apiKey: "AIzaSyCdXUtqrrtVwTFN-FcnbnqCHCzkfDfK-Zg",
  authDomain: "sudoto-app.firebaseapp.com",
  projectId: "sudoto-app",
  storageBucket: "sudoto-app.appspot.com",
  messagingSenderId: "270438936342",
  appId: "1:270438936342:web:416d57b1aed27b14032a37",
  measurementId: "G-VN2DDSLXQW",
  databaseURL: "https://sudoto-app-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const LogoutPage = () => {
  useEffect(() => {
    signOut(auth)
      .then(() => {
        window.location = "/";
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  }, []);

  return (
    <div>
      <h2>Logging out...</h2>
    </div>
  );
};

export default LogoutPage;
