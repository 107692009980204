import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image-c.png";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { handleSignup } from "./Navbar";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">
          {t("aboutpage.primary-subheading")}
        </p>
        <h1 className="primary-heading">{t("aboutpage.primary-heading")}</h1>
        <p className="primary-text">{t("aboutpage.description1")}</p>
        <p className="primary-text">{t("aboutpage.description2")}</p>
        <div className="about-buttons-container">
          <button className="secondary-button" onClick={handleSignup}>
            {t("aboutpage.cta")}
          </button>
        </div>
      </div>
    </div>
  );
};

/*
<button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button>
          */

export default About;
